import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { trpc } from "../../../lib/trpc";
import AppTitle from "../../HomeScreen/components/AppTitle";
import { CCMultipleWorkOrderTrend } from "./CommandCentreGraphs/CCMultipleWorkOrderTrend";
import { CCWorkOrderTrend } from "./CommandCentreGraphs/CCWorkOrderTrend";
import { CCWorkOrderTypeApportionment } from "./CommandCentreGraphs/CCWorkOrderTypeApportionment";
import { DateFilter } from "./WorkRequestAnalytics/DateFilter";

export default function CCWorkOrderDashboard() {
  const projects = trpc.analytics.getProjectsUnderRCI.useQuery();

  return (
    <>
      <AppTitle title="Work Order Analytics" />
      <Tabs defaultValue="0">
        <TabsList>
          <TabsTrigger value="0">
            <p>Trends</p>
          </TabsTrigger>
          <TabsTrigger value="1">
            <p>Breakdown</p>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          <div className="flex items-center justify-between my-4">
            <DateFilter />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-3">
              <CCMultipleWorkOrderTrend projects={projects.data ?? []} />
            </div>
            {projects.data?.map((p) => (
              <CCWorkOrderTrend key={p.id} project={p} />
            ))}
          </div>
        </TabsContent>
        <TabsContent value="1">
          <div className="flex items-center justify-between my-4">
            <DateFilter />
          </div>
          <div className="grid grid-cols-3 gap-4">
            {projects.data?.map((p) => (
              <CCWorkOrderTypeApportionment key={p.id} project={p} />
            ))}
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
}
