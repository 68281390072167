import PricingSection from "../OthersTab/components/PricingSection";
import RevenueSection from "./components/RevenueSection";
import SummarySection from "./components/SummarySection";

export default function ProfitLossTab() {
  return (
    <div className="flex flex-col gap-10">
      <SummarySection />
      <PricingSection />
    </div>
  );
}
