import { TabsContent, TabsTrigger } from "../../../components/ui/tabs";
import { TabsList } from "../../../components/ui/tabs";
import { DashboardProps } from "../dashboards";
import ExpensesAnalytic from "./ExpensesAnalytic/ExpensesAnalytic";
import LegalComplianceAnalytics from "./LegalComplianceAnalytics/LegalComplianceAnalytics";
import OasisSquareLegalComplianceAnalytics from "./LegalComplianceAnalytics/OasisSquareLegalComplianceAnalytics";
import { PMAnalytic } from "./PMAnalytics/PMAnalytic";
import { WorkOrderAnalytics } from "./WorkOrderAnalytics/WorkOrderAnalytics";
import { WorkRequestAnalytics } from "./WorkRequestAnalytics/WorkRequestAnalytics";

export default function OasisDashboard({ currentTabIdx }: DashboardProps) {
  return (
    <>
      <TabsList>
        <TabsTrigger value="0">
          <p>Work Request</p>
        </TabsTrigger>
        <TabsTrigger value="1">
          <p>Work Order</p>
        </TabsTrigger>
        <TabsTrigger value="2">
          <p>Preventive Maintenance</p>
        </TabsTrigger>
        <TabsTrigger value="4">
          <p>Legal Compliance</p>
        </TabsTrigger>
        <TabsTrigger value="5">
          <p>Expenses</p>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="0">
        <WorkRequestAnalytics />
      </TabsContent>
      <TabsContent value="1">
        <WorkOrderAnalytics />
      </TabsContent>
      <TabsContent value="2">
        <PMAnalytic />
      </TabsContent>
      <TabsContent value="4">
        <OasisSquareLegalComplianceAnalytics />
      </TabsContent>
      <TabsContent value="5">
        <ExpensesAnalytic />
      </TabsContent>
    </>
  );
}
