import { RouterOutputs } from "@cerev-cmms/trpc";
import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../ui/checkbox";

type Asset = RouterOutputs["assets"]["getFullAssets"][number];

export const pmTemplateAssetColumns: ColumnDef<Asset>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    header: "Name",
    accessorFn: (row) => row.name,
  },
  {
    header: "Asset Type",
    accessorFn: (row) => row.assetType?.name ?? "-",
  },
  {
    header: "Location",
    accessorFn: (row) => row.location?.name ?? "-",
  },
  {
    header: "Code",
    accessorFn: (row) => row.code ?? "-",
  },
];
