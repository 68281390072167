import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
} from "@/components/ui/chart";
import { Loader2 } from "lucide-react";
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

const formatKWh = (value: number) => {
  return value.toLocaleString("en-MY", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }) + " kWh";
};

const formatWithHideZero = (value: number, formatFn: (value: number) => string) => {
  if (value === 0) return null;
  return formatFn(value);
};

export function KWhByMonth() {
  const [activeDescription, setActiveDescription] = useState<string | null>(null);
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : new Date().getFullYear();

  const { data, isLoading } = trpc.tnb.getkWhReadingByMonth.useQuery(
    {
      projectId: project?.id?.toString() ?? "0",
      year,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No kWh readings available for the selected period
          </div>
        </CardContent>
      </Card>
    );
  }

  // Get all description keys
  const descriptionKeys = Object.keys(data.chartConfig);

  const getBarOpacity = (descKey: string) => {
    if (!activeDescription) return 1;
    return activeDescription === descKey ? 1 : 0.3;
  };

  // Calculate total for each period
  const getTotalValue = (data: any) => {
    return descriptionKeys.reduce((sum, key) => sum + (data[key] || 0), 0);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>kWh Usage</CardTitle>
        <CardDescription>
          {data.period} breakdown of electricity consumption
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[350px] w-full"
        >
          <BarChart 
            data={data.chartData} 
            accessibilityLayer
            margin={{ top: 40, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value: number) => formatKWh(value)}
              width={100}
            />
            <ChartTooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length > 0) {
                  // Filter to only show the active description's data if there's a hover
                  const displayPayload = activeDescription
                    ? payload.filter(p => p.dataKey === activeDescription)
                    : payload;

                  return (
                    <div className="rounded-lg border bg-white p-2 shadow-sm">
                      <div className="text-sm font-medium">{label}</div>
                      {displayPayload.map((entry: any) => (
                        <div
                          key={entry.dataKey}
                          className="flex items-center gap-2"
                        >
                          <div
                            className="h-2 w-2 rounded-full"
                            style={{ backgroundColor: entry.fill }}
                          />
                          <span className="text-sm text-muted-foreground">
                            {data.chartConfig[entry.dataKey].label}:
                          </span>
                          <span className="text-sm font-medium">
                            {formatKWh(entry.value)}
                          </span>
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              }}
            />
            {descriptionKeys.map((descKey) => (
              <Bar
                key={descKey}
                dataKey={descKey}
                name={data.chartConfig[descKey].label}
                fill={data.chartConfig[descKey].color}
                stackId="descriptions"
                opacity={getBarOpacity(descKey)}
                onMouseEnter={() => setActiveDescription(descKey)}
                onMouseLeave={() => setActiveDescription(null)}
              >
                {descKey === descriptionKeys[descriptionKeys.length - 1] && (
                  <LabelList
                    dataKey={(entry: any) => getTotalValue(entry)}
                    position="top"
                    formatter={(value: number) => formatWithHideZero(value, formatKWh)}
                    style={{ fill: '#0f172a', fontWeight: "bold" }}
                    offset={8}
                  />
                )}
              </Bar>
            ))}
          </BarChart>
        </ChartContainer>

        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
          {descriptionKeys.map((descKey) => (
            <div
              key={descKey}
              className="flex items-center space-x-2 rounded-lg bg-card p-2 hover:bg-accent/10 cursor-pointer"
              onMouseEnter={() => setActiveDescription(descKey)}
              onMouseLeave={() => setActiveDescription(null)}
              style={{
                opacity: getBarOpacity(descKey),
              }}
            >
              <div className="flex items-center space-x-2">
                <div
                  className="h-3 w-3 rounded-sm"
                  style={{
                    backgroundColor: data.chartConfig[descKey].color,
                  }}
                />
                <div className="flex flex-col">
                  <span className="text-xs font-medium text-foreground">
                    {data.chartConfig[descKey].label}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
