import { useAppSelector } from "../../../redux/store";
import { KWhByMonth } from "./components/kWhByMonth";
import { RMByMonth } from "./components/RMByMonth";
import { YearFilter } from "./components/YearFilter";

export default function TnbBillGraphTab() {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  return (
    <div className="space-y-4">
      <div className="flex justify-start">
        <YearFilter />
      </div>
      <KWhByMonth />
      <RMByMonth />
    </div>
  );
}
