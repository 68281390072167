import { trpc } from "../../../lib/trpc";
import AppTitle from "../../HomeScreen/components/AppTitle";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { Loader2, Building2, Wrench } from "lucide-react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

// Array of colors for different asset types
const CHART_COLORS = [
  "var(--chart-1)", // Using ShadCN chart colors
  "var(--chart-2)",
  "var(--chart-3)",
  "var(--chart-4)",
  "var(--chart-5)",
];

export function CCEquipmentDashboard() {
  const { data, isLoading } =
    trpc.analytics.getMultipleAssetsUnderRCI.useQuery();

  if (isLoading) {
    return (
      <>
        <AppTitle title="Equipments" />
        <div className="w-full h-[400px] flex items-center justify-center">
          <Loader2 className="h-12 w-12 animate-spin text-primary" />
        </div>
      </>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <>
        <AppTitle title="Equipments" />
        <div className="w-full h-[400px] flex items-center justify-center">
          No equipment data available
        </div>
      </>
    );
  }

  // Calculate total assets across all projects
  const totalAssets = data.chartData.reduce((total, project) => {
    return total + project.data.reduce((sum, type) => sum + type.count, 0);
  }, 0);

  // Get total number of asset types across all projects
  const uniqueAssetTypes = new Set(
    data.chartData.flatMap((project) => project.data.map((type) => type.name))
  ).size;

  return (
    <>
      <AppTitle title="Equipment Analytics" />
      <Tabs defaultValue="0">
        <TabsList>
          <TabsTrigger value="0">
            <p>Portfolio</p>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          {/* Summary Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
            <Card className="bg-gradient-to-br from-blue-50 to-blue-100">
              <CardContent className="pt-6">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-blue-500 rounded-lg">
                    <Building2 className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <p className="text-sm text-blue-900 font-medium">
                      Total Projects
                    </p>
                    <h3 className="text-2xl font-bold text-blue-950">
                      {data.chartData.length}
                    </h3>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card className="bg-gradient-to-br from-green-50 to-green-100">
              <CardContent className="pt-6">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-green-500 rounded-lg">
                    <Wrench className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <p className="text-sm text-green-900 font-medium">
                      Total Equipment
                    </p>
                    <h3 className="text-2xl font-bold text-green-950">
                      {totalAssets}
                    </h3>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card className="bg-gradient-to-br from-purple-50 to-purple-100">
              <CardContent className="pt-6">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-purple-500 rounded-lg">
                    <Building2 className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <p className="text-sm text-purple-900 font-medium">
                      Equipment Categories
                    </p>
                    <h3 className="text-2xl font-bold text-purple-950">
                      {uniqueAssetTypes}
                    </h3>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Project Breakdown */}
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Equipment Distribution by Project</CardTitle>
              <CardDescription>
                Breakdown of equipment types across different projects
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ChartContainer
                config={data.chartConfig}
                className="h-[400px] w-full"
              >
                <BarChart
                  data={data.chartData}
                  accessibilityLayer
                  margin={{ left: 40, right: 40, top: 20, bottom: 40 }}
                >
                  {data.chartData[0]?.data.map((_, index) => (
                    <Bar
                      key={index}
                      dataKey={`data[${index}].count`}
                      name={data.chartData[0].data[index].name}
                      stackId="a"
                      fill={`hsl(var(--chart-${(index % 5) + 1}))`}
                      radius={[4, 4, 0, 0]}
                    />
                  ))}
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    axisLine={true}
                    tick={{ fill: "hsl(var(--foreground))", fontSize: 12 }}
                    tickMargin={8}
                    label={{
                      value: "Projects",
                      position: "bottom",
                      offset: 20,
                      fill: "hsl(var(--foreground))",
                      fontSize: 14,
                    }}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={true}
                    tick={{ fill: "hsl(var(--foreground))", fontSize: 12 }}
                    tickMargin={8}
                    label={{
                      value: "Number of Equipment",
                      angle: -90,
                      position: "left",
                      offset: 20,
                      fill: "hsl(var(--foreground))",
                      fontSize: 14,
                    }}
                  />
                  <ChartTooltip
                    content={
                      <ChartTooltipContent
                        style={{
                          backgroundColor: "hsl(var(--background))",
                          border: "1px solid hsl(var(--border))",
                          borderRadius: "6px",
                          padding: "8px 12px",
                        }}
                      />
                    }
                  />
                </BarChart>
              </ChartContainer>
            </CardContent>
          </Card>

          {/* Individual Project Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {data.chartData.map((project, index) => {
              // Process data to show top 10 and group others
              const sortedData = [...project.data].sort(
                (a, b) => b.count - a.count
              );
              const top10 = sortedData.slice(0, 10);
              const others = sortedData.slice(10);
              const othersCount = others.reduce(
                (sum, item) => sum + item.count,
                0
              );

              const displayData =
                othersCount > 0
                  ? [...top10, { name: "Others", count: othersCount }]
                  : top10;

              const totalCount = displayData.reduce(
                (sum, type) => sum + type.count,
                0
              );

              return (
                <Card key={project.name} className="flex flex-col">
                  <CardHeader className="pb-2">
                    <CardTitle className="text-lg font-semibold">
                      {project.name}
                    </CardTitle>
                    <CardDescription className="text-sm">
                      {others.length > 0
                        ? `Top 10 of ${project.data.length} equipment types`
                        : "Equipment breakdown"}
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="flex-1">
                    {/* Total Equipment Count */}
                    <div className="mb-6 pb-4 border-b">
                      <div className="text-sm text-muted-foreground">
                        Total Equipment
                      </div>
                      <div className="text-2xl font-bold">{totalCount}</div>
                    </div>

                    {/* Equipment Type Breakdown */}
                    <div className="space-y-4">
                      {displayData.map((type, typeIndex) => (
                        <div key={type.name} className="space-y-2">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                              <span className="text-sm font-medium">
                                {type.name}
                                {type.name === "Others" && (
                                  <span className="text-xs text-muted-foreground ml-1">
                                    ({others.length} types)
                                  </span>
                                )}
                              </span>
                            </div>
                            <span className="text-sm tabular-nums">
                              {type.count}
                            </span>
                          </div>

                          <div className="h-2 rounded-full bg-muted overflow-hidden">
                            <div
                              className="h-full rounded-full transition-all"
                              style={{
                                width: `${(type.count / totalCount) * 100}%`,
                                backgroundColor:
                                  type.name === "Others"
                                    ? "hsl(var(--muted-foreground))"
                                    : `hsl(var(--chart-${
                                        (typeIndex % 5) + 1
                                      }))`,
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
}
