import { useFormContext, useWatch } from "react-hook-form";
import { useMemo } from "react";
import { cn } from "../../../lib/utils";
import Decimal from "decimal.js";

interface FormValues {
  monthlyPaymentValue: string;
  maximumApd: string;
  kpiRows: {
    weightage: string;
    actual: string;
    target: string;
    [key: string]: any;
  }[];
}

interface ApdValueDeductedCellProps {
  index: number;
}

export function ApdValueDeductedCell({ index }: ApdValueDeductedCellProps) {
  const { control } = useFormContext<FormValues>();

  const [monthlyPaymentValue, maximumApd, rowWeightage, actual, target] = useWatch({
    control,
    name: [
      'monthlyPaymentValue',
      'maximumApd',
      `kpiRows.${index}.weightage`,
      `kpiRows.${index}.actual`,
      `kpiRows.${index}.target`
    ],
  });

  const apdValue = useMemo(() => {
    try {
      const mpv = new Decimal(monthlyPaymentValue || '0');
      const maxApd = new Decimal(maximumApd || '0');
      const weightage = new Decimal(rowWeightage || '0');
      
      return mpv
        .times(weightage)
        .times(maxApd)
        .dividedBy(10000);
    } catch (error) {
      return new Decimal('0');
    }
  }, [monthlyPaymentValue, maximumApd, rowWeightage]);

  const computedValue = useMemo(() => {
    try {
      const actualValue = parseFloat(actual || '0');
      const targetValue = parseFloat(target || '0');
      const value = actualValue < targetValue ? apdValue.toNumber() : 0;

      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    } catch (error) {
      return '0.00';
    }
  }, [actual, target, apdValue]);

  const showRed = actual && target && parseFloat(actual) < parseFloat(target);

  return (
    <td className={cn(
      "border p-2 text-sm text-right",
      showRed && "text-red-600 font-bold"
    )}>
      {computedValue}
    </td>
  );
} 