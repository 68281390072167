import { format, parseISO } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import DatePicker from "./DatePicker";

interface AppDatePickerProps {
  name: string;
  label: string;
  disabled?: (date: Date) => boolean;
  className?: string;
}

export function AppDatePicker({ name, label, disabled, className }: AppDatePickerProps) {
  const form = useFormContext();

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn("print:border-none print:shadow-none print:p-0 print:bg-transparent", className)}>
          <DatePicker
            label={label}
            value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
            onChange={(date) => {
              field.onChange(date);
            }}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
