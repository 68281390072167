import { ColumnDef } from "@tanstack/react-table";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { DRAWER_VIEW_STATE, LEGAL_COMPLIANCE_DETAIL_DRAWER } from "@/components/drawers/AppDrawer";

type StatutoryContract = {
  id: number;
  name: string;
  description: string | null;
  expiryDate: Date;
};

export function useColumns() {
  const [, setSearchParams] = useSearchParams();

  const columns: ColumnDef<StatutoryContract>[] = [
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => (
        <span
          className="cursor-pointer underline text-primary hover:text-primary/80"
          onClick={() => {
            setSearchParams((params) => {
              params.set("drawerType", LEGAL_COMPLIANCE_DETAIL_DRAWER);
              params.set("drawerState", DRAWER_VIEW_STATE);
              params.set("legalComplianceId", row.original.id.toString());
              return params;
            });
          }}
        >
          {row.original.name}
        </span>
      ),
    },
    {
      accessorKey: "description",
      header: "Description",
      cell: ({ row }) => row.original.description || "-",
    },
    {
      accessorKey: "expiryDate",
      header: "Expiry Date",
      cell: ({ row }) => format(new Date(row.original.expiryDate), "dd/MM/yyyy"),
    },
    {
      id: "status",
      header: "Status",
      cell: () => {
        return <Badge variant="destructive" className="bg-red-100 text-red-800">Expired</Badge>;
      },
    },
  ];

  return columns;
} 