import { useSearchParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import {
  DRAWER_CREATE_STATE,
  INVENTORY_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import InventoryTab from "./tabs/InventoryTab";
import InventoryTypeTab from "./tabs/InventoryTypeTab";
import InventoryPolicyGuard from "../../guards/InventoryPolicyGuard";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import { CerevModule } from "../../api/model";
import InventoryActions from "./components/InventoryActions";

export default function InventoryScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  return (
    <ModuleAccessGuard module={CerevModule.INVENTORY}>
      <div className="flex gap-4 justify-between items-center">
        <div className="flex gap-4 items-center mb-4">
          <AppTitle title="Inventory" className="mb-0" />
          <InventoryPolicyGuard>
            <AppButton
              label="+ Add"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", INVENTORY_FORM_DRAWER);
                  p.set("drawerState", DRAWER_CREATE_STATE);
                  return p;
                });
              }}
            />
          </InventoryPolicyGuard>
        </div>
        <InventoryActions />
      </div>
      <Tabs className="mb-4" defaultValue="inventories">
        <TabsList className="mb-2">
          <TabsTrigger value="inventories">Inventory</TabsTrigger>
          <TabsTrigger value="inventoryType">Inventory Types</TabsTrigger>
        </TabsList>
        <TabsContent value="inventories">
          <InventoryTab />
        </TabsContent>
        <TabsContent value="inventoryType">
          <InventoryTypeTab />
        </TabsContent>
      </Tabs>
    </ModuleAccessGuard>
  );
}
