import React from "react";
import { trpc } from "../../../../lib/trpc";
import { useSearchParams } from "react-router-dom";
import { PieChart, Pie, Cell, Legend, Label } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Project } from "@cerev-cmms/prisma";

const getKpiColor = (percentage: number) => {
  if (percentage >= 80) return "green";
  if (percentage >= 50) return "orange";
  return "red";
};

export function CCWorkRequestStatusBreakdownPieChart({
  project,
}: {
  project: Project;
}) {
  const [searchParams] = useSearchParams();

  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } =
    trpc.analytics.getWorkRequestStatusBreakdown.useQuery(
      {
        projectId: project.id,
        month,
        year,
      },
      {
        enabled: !!project,
      }
    );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No work request status data available
          </div>
        </CardContent>
      </Card>
    );
  }

  // Add total to each data point for percentage calculation
  const chartDataWithTotal = data.chartData.map((item) => ({
    ...item,
    total: data.totalRequests,
    percentage: ((item.value / data.totalRequests) * 100).toFixed(1),
  }));

  // Calculate completion percentage
  const completedData = chartDataWithTotal.find(
    (item) => item.name.toLowerCase() === "completed"
  );
  const completionPercentage = completedData
    ? parseFloat(completedData.percentage)
    : 0;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{project.name}</CardTitle>
        <CardDescription>
          Distribution of work requests by status
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[300px] w-full"
        >
          <PieChart>
            <Pie
              data={chartDataWithTotal}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              innerRadius={60}
              startAngle={90}
              endAngle={-270}
            >
              {chartDataWithTotal.map((entry) => (
                <Cell
                  key={entry.name}
                  fill={data.chartConfig[entry.name.toLowerCase()]?.color}
                />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox?.cy ? viewBox.cy - 10 : 0}
                          className="fill-foreground text-xl font-bold"
                          fill={getKpiColor(completionPercentage)}
                        >
                          {completionPercentage}%
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={viewBox?.cy ? viewBox.cy + 14 : 0}
                          className="fill-muted-foreground text-sm"
                          fill={getKpiColor(completionPercentage)}
                        >
                          COMPLETED
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <ChartTooltip
              content={<ChartTooltipContent className="space-x-4" />}
            />
          </PieChart>
        </ChartContainer>
        <div className="mt-6">
          <div className="grid grid-cols-2">
            {chartDataWithTotal.map((item) => (
              <div
                key={item.name}
                className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm transition-colors hover:bg-accent/10"
              >
                <div className="flex items-center space-x-4">
                  <div
                    className="h-3 w-3 rounded-sm"
                    style={{
                      backgroundColor:
                        data.chartConfig[item.name.toLowerCase()]?.color,
                    }}
                  />
                  <div className="flex flex-col">
                    <span className="text-xs font-medium text-foreground">
                      {item.name}
                    </span>
                    <span className="text-sm font-semibold">
                      {item.value} ({item.percentage}%)
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 text-center">
            <p className="text-sm text-muted-foreground">Total Work Requests</p>
            <p className="text-2xl font-bold">{data.totalRequests}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
