import React from "react";
import { trpc } from "../../../../lib/trpc";
import { PieChart, Pie, Cell, Label } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import AppButton from "@/components/AppButton";
import { useSearchParams } from "react-router-dom";
import { Project } from "@cerev-cmms/prisma";

interface StaffWorkOrderData {
  name: string;
  value: number;
  completed: number;
  inProgress: number;
  isClosed: number;
  total: number;
}

export function CCUsersOnWorkOrders({ project }: { project: Project }) {
  const [showAllStaff, setShowAllStaff] = React.useState(false);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data: staffData, isLoading } = trpc.analytics.getStaffWithWorkOrderCount.useQuery(
    {
      projectId: project.id,
      month,
      year,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full h-[400px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!staffData || staffData.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full h-[400px] flex items-center justify-center text-muted-foreground">
            No work order data available
          </div>
        </CardContent>
      </Card>
    );
  }

  // Process data for pie chart - top 5 + others
  const processChartData = (inputData: {
    chartData: StaffWorkOrderData[];
    totalWorkOrders: number;
  }) => {
    if (!inputData || inputData.chartData.length === 0) return [];

    const top5 = inputData.chartData.slice(0, 5);
    const others = inputData.chartData.slice(5);
    const othersTotal = others.reduce((sum: number, item: StaffWorkOrderData) => sum + item.value, 0);

    if (othersTotal > 0) {
      return [
        ...top5,
        {
          name: "Others",
          value: othersTotal,
          completed: others.reduce((sum: number, item: StaffWorkOrderData) => sum + item.completed, 0),
          inProgress: others.reduce((sum: number, item: StaffWorkOrderData) => sum + item.inProgress, 0),
          isClosed: others.reduce((sum: number, item: StaffWorkOrderData) => sum + item.isClosed, 0),
          total: staffData.totalWorkOrders,
        },
      ];
    }

    return top5;
  };

  const pieChartData = processChartData(staffData);

  return (
    <Card>
      <CardHeader>
        <CardTitle>{project.name}</CardTitle>
        <CardDescription>Staff Work Order Distribution</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer className="mx-auto aspect-square max-h-[300px] w-full" config={staffData.chartConfig}>
          <PieChart>
            <Pie
              data={pieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              innerRadius={0}
              startAngle={90}
              endAngle={-270}
            >
              {pieChartData.map((entry: StaffWorkOrderData, index: number) => (
                <Cell
                  key={entry.name}
                  fill={entry.name === "Others" 
                    ? "hsl(var(--muted-foreground))" 
                    : `hsl(var(--chart-${(index % 5) + 1}))`}
                />
              ))}
            </Pie>
            <ChartTooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload as StaffWorkOrderData;
                  const percentage = ((data.value / data.total) * 100).toFixed(1);
                  return (
                    <div className="rounded-lg border bg-white p-2 shadow-sm">
                      <div className="grid gap-2">
                        <p className="font-semibold">
                          {data.name}
                          {data.name === "Others" && ` (${staffData.chartData.length - 5} staff)`}
                        </p>
                        <div className="grid gap-1">
                          <div className="flex items-center gap-2">
                            <div className="h-2 w-2 rounded-full bg-green-500" />
                            <span className="text-sm">
                              Completed: {data.completed}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="h-2 w-2 rounded-full bg-yellow-500" />
                            <span className="text-sm">
                              In Progress: {data.inProgress}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="h-2 w-2 rounded-full bg-red-500" />
                            <span className="text-sm">
                              Closed: {data.isClosed}
                            </span>
                          </div>
                          <div className="mt-1 pt-1 border-t">
                            <span className="text-sm font-medium">
                              Total: {data.value} ({percentage}%)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              }}
            />
          </PieChart>
        </ChartContainer>

        {/* Legend */}
        <div className="grid grid-cols-2">
          {pieChartData.map((staff: StaffWorkOrderData, index: number) => (
            <div
              key={staff.name}
              className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm transition-colors hover:bg-accent/10"
            >
              <div className="flex items-center space-x-2">
                <div
                  className="h-3 w-3 rounded-sm"
                  style={{
                    backgroundColor: staff.name === "Others"
                      ? "hsl(var(--muted-foreground))"
                      : `hsl(var(--chart-${(index % 5) + 1}))`
                  }}
                />
                <div className="flex flex-col">
                  <span className="text-xs font-medium text-foreground">
                    {staff.name}
                    {staff.name === "Others" && ` (${staffData.chartData.length - 5} staff)`}
                  </span>
                  <span className="text-sm font-semibold">
                    {((staff.value / staffData.totalWorkOrders) * 100).toFixed(1)}%
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* View All Button */}
        {staffData.chartData.length > 5 && (
          <div className="mt-4 flex justify-center">
            <AppButton
              variant="outline"
              label="View All Staff"
              onClick={() => setShowAllStaff(true)}
            />
          </div>
        )}

        {/* View All Dialog */}
        <Dialog open={showAllStaff} onOpenChange={setShowAllStaff}>
          <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>All Staff Work Orders</DialogTitle>
            </DialogHeader>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
              {staffData.chartData.map((staff, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm"
                >
                  <div className="flex items-center space-x-2">
                    <div
                      className="h-3 w-3 rounded-sm"
                      style={{
                        backgroundColor: `hsl(var(--chart-${(index % 5) + 1}))`,
                      }}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs font-medium text-foreground">
                        {staff.name}
                      </span>
                      <span className="text-sm font-semibold">
                        {((staff.value / staffData.totalWorkOrders) * 100).toFixed(1)}% ({staff.value})
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
}

export default CCUsersOnWorkOrders;