import { z } from "zod";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/store";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetUserDataQuery } from "../../../redux/slices/Auth/AuthApi";
import AppTextField from "../../AppTextField";
import AppDocumentUpload from "../../AppDocumentUpload";
import AppButton from "../../AppButton";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import AppTextAreaField from "../../AppTextAreaField";
import { workScopeColumns } from "./columns/workScopeColumns";
import { trpc } from "../../../lib/trpc";
import useAppStorage from "../../../hooks/useAppStorage";
import { RouterOutputs } from "@cerev-cmms/trpc";
import AppDocumentUploadTRPC from "../../AppDocumentUploadTRPC";

const VendorFormSchema = z.object({
  name: z.string().min(1, { message: "Vendor name is required" }),
  workScope: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array()
    .min(1, { message: "Work scope is required" }),
  contactName: z.string().min(1, { message: "Contact name is required" }),
  telNo: z.string().min(1, { message: "Telephone number is required" }),
  companyWebSite: z.string().optional(),
  businessAddress: z.string().optional(),
  remark: z.string().optional(),
  additionalDocuments: z.instanceof(File).array().optional(),
});

export type VendorForm = z.infer<typeof VendorFormSchema>;
type Attachment =
  RouterOutputs["vendor"]["getOneVendor"]["attachments"][number];
type WorkScope = RouterOutputs["vendor"]["getWorkScopes"][number];

export default function VendorFormDrawer() {
  const utils = trpc.useUtils();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const [searchParams, setSearchParams] = useSearchParams();
  const vendorId = searchParams.get("vendorId");
  const [uploadedDocs, setUploadedDocs] = useState<Attachment[]>([]);
  const methods = useForm<VendorForm>({
    resolver: zodResolver(VendorFormSchema),
    defaultValues: {
      name: "",
      workScope: [],
      contactName: "",
      telNo: "",
      companyWebSite: "",
      businessAddress: "",
      remark: "",
      additionalDocuments: [],
    },
  });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: user } = useGetUserDataQuery();

  const { data: editVendor, isLoading: editVendorIsLoading } =
    trpc.vendor.getOneVendor.useQuery(vendorId ?? "", {
      enabled: !!isUpdateDrawer && !!vendorId,
    });

  const { mutate: createVendor } = trpc.vendor.createVendor.useMutation({
    onSuccess: () => {
      utils.vendor.getVendors.invalidate();
      setSearchParams(new URLSearchParams());
    },
  });

  const { mutate: updateVendor } = trpc.vendor.updateVendor.useMutation({
    onSuccess: () => {
      utils.vendor.getVendors.invalidate();
      setSearchParams(new URLSearchParams());
    },
  });

  const { data: workScopes } = trpc.vendor.getWorkScopes.useQuery(
    {
      companyId: activeComp?.id.toString() ?? "",
    },
    {
      enabled: !!activeComp,
    }
  );

  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      utils.vendor.getVendors.invalidate();
      setSearchParams(new URLSearchParams());
    },
  });

  const { mutate: deleteDocument } = trpc.vendor.deleteAttachment.useMutation({
    onSuccess: () => {
      utils.vendor.getVendors.invalidate();
    },
  });

  const onDeleteUploadedDoc = async (att: Attachment) => {
    const newUploadedDocs = uploadedDocs.filter((v) => v.id !== att.id);
    if (!editVendor || !att || !att.id) return;
    deleteDocument({
      attachmentId: att.id.toString(),
      vendorId: editVendor.id.toString(),
    });
    setUploadedDocs(newUploadedDocs);
  };

  const onSubmit: SubmitHandler<VendorForm> = async (data) => {
    if (!activeProj || !user || !activeComp) return;

    mutate({
      files: data.additionalDocuments ?? [],
      mutateAsync: async (atts) => {
        if (isUpdateDrawer) {
          if (!editVendor) return;
          await updateVendor({
            vendorId: editVendor.id.toString(),
            data: {
              updatedById: user.id.toString(),
              name: data.name,
              contactName: data.contactName,
              contactNo: data.telNo,
              companyWebsite: data.companyWebSite ?? "",
              remarks: data.remark ?? "",
              businessAddress: data.businessAddress ?? "",
              workScopeIds: data.workScope.map((ws) => ws.id),
              attachments: atts.map((a) => ({
                name: a.name ?? "",
                fileSizeMb: a.fileSizeMb ?? "",
                gsPath: a.gsPath ?? "",
                url: a.url ?? "",
                underProject: { id: activeProj.id ?? 0 },
                underProjectId: activeProj.id ?? 0,
              })),
              companyId: activeComp.id,
            },
          });
        } else {
          await createVendor({
            name: data.name,
            contactName: data.contactName,
            contactNo: data.telNo,
            companyWebsite: data.companyWebSite ?? "",
            remarks: data.remark ?? "",
            businessAddress: data.businessAddress ?? "",
            workScopeIds: data.workScope.map((ws) => ws.id),
            attachments: atts.map((a) => ({
              name: a.name ?? "",
              fileSizeMb: a.fileSizeMb ?? "",
              gsPath: a.gsPath ?? "",
              url: a.url ?? "",
              underProject: { id: activeProj.id ?? 0 },
              underProjectId: activeProj.id ?? 0,
            })),
            companyId: activeComp.id,
          });
        }
      },
    });
  };

  useEffect(() => {
    if (isUpdateDrawer && editVendor) {
      methods.setValue("name", editVendor.name ?? "");
      methods.setValue("contactName", editVendor.contactName ?? "");
      methods.setValue("telNo", editVendor.contactNo ?? "");
      methods.setValue("companyWebSite", editVendor.companyWebsite ?? "");
      methods.setValue("businessAddress", editVendor.businessAddress ?? "");
      methods.setValue("remark", editVendor.remarks ?? "");
      methods.setValue(
        "workScope",
        editVendor.workscope.map((ws) => ({
          id: ws.id,
          name: ws.name ?? "",
        }))
      );
      setUploadedDocs(editVendor?.attachments ?? []);
    }
  }, [isUpdateDrawer, editVendor]);

  if (editVendorIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Vendor
        </p>
        <AppTextField label="Name" name="name" />
        <AppSelectWithDialog
          multiple
          columns={workScopeColumns as any[]}
          label="Work Scope"
          placeholder="Select Workscopes"
          defaultValue={[]}
          control={methods.control}
          name="workScope"
          items={workScopes ?? [] as any[]}
          onResultRender={(item, idx) => {
            return <div key={idx}>{item?.name}</div>;
          }}
          onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
          dialogTitle="Select WorkScope"
        />
        <AppTextField label="Contact Name" name="contactName" />
        <AppTextField label="Telephone No." name="telNo" />
        <AppTextField label="Company Website" name="companyWebSite" />
        <AppTextAreaField label="Remarks" name="remark" />
        <AppTextField label="Business Address" name="businessAddress" />
        <Controller
          control={methods.control}
          name="additionalDocuments"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUploadTRPC
                uploadedDocs={uploadedDocs}
                onDeleteUploadedDoc={onDeleteUploadedDoc}
                label="Additional Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newSetPhotos = value.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.additionalDocuments?.message !== ""
                    ? "At least one file required"
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex">
          <AppButton
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isPending}
          />
        </div>
      </div>
    </FormProvider>
  );
}
