import { TabsContent } from "../../../components/ui/tabs";

import { TabsList, TabsTrigger } from "../../../components/ui/tabs";
import WorkOrderPricingPolicyGuard from "../../../guards/WorkOrderPricingPolicyGuard";
import { PMAnalytic } from "./PMAnalytics/PMAnalytic";
import { PnLAnalytic } from "./PnLAnalytic/PnLAnalytic";
import RevenueAnalytic from "./RevenueAnalytic/RevenueAnalytic";
import { WorkOrderAnalytics } from "./WorkOrderAnalytics/WorkOrderAnalytics";

export default function IPMDashboard() {
  return (
    <>
      <TabsList>
        <TabsTrigger value="0">
          <p>Work Order</p>
        </TabsTrigger>
        <TabsTrigger value="1">
          <p>Preventive Maintenance</p>
        </TabsTrigger>
        <WorkOrderPricingPolicyGuard>
          <TabsTrigger value="2">
            <p>P&L</p>
          </TabsTrigger>
        </WorkOrderPricingPolicyGuard>
        <WorkOrderPricingPolicyGuard>
          <TabsTrigger value="3">
            <p>Revenue</p>
          </TabsTrigger>
        </WorkOrderPricingPolicyGuard>
      </TabsList>
      <TabsContent value="0">
        <WorkOrderAnalytics />
      </TabsContent>
      <TabsContent value="1">
        <PMAnalytic />
      </TabsContent>
      <WorkOrderPricingPolicyGuard>
        <TabsContent value="2">
          <PnLAnalytic />
        </TabsContent>
      </WorkOrderPricingPolicyGuard>
      <WorkOrderPricingPolicyGuard>
        <TabsContent value="3">
          <RevenueAnalytic />
        </TabsContent>
      </WorkOrderPricingPolicyGuard>
    </>
  );
}
