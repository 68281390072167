import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormLabel } from "@/components/ui/form";
import { Rating } from "@mui/material";
import { Controller } from "react-hook-form";
import AppButton from "@/components/AppButton";
import AppTextAreaField from "@/components/AppTextAreaField";
import AppTextField from "@/components/AppTextField";
import AppCameraFieldFile from "@/components/AppCameraFieldFiles";
import { UseFormReturn } from "react-hook-form";
import { FormType } from "./types";

interface VerificationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  form: UseFormReturn<FormType>;
  onSubmit: (data: FormType) => Promise<void>;
  workOrderId: string | null;
  isPending: boolean;
  onDeleteSignatory: (workOrderId: number) => void;
}

export function VerificationDialog({
  isOpen,
  onClose,
  form,
  onSubmit,
  workOrderId,
  isPending,
  onDeleteSignatory,
}: VerificationDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(o) => {
        onClose();
        if (!o) form.reset();
      }}
    >
      <DialogContent className="max-w-2xl overflow-y-scroll max-h-screen">
        <DialogHeader>
          <DialogTitle>Update Verification</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <div className="space-y-4">
            <AppTextAreaField
              label="Closing Remark"
              name="closingRemark"
              placeholder="Enter closing remarks..."
            />
            <div className="flex flex-col gap-2 mt-4">
              <FormLabel className="font-sans">Quality Rating</FormLabel>
              <Controller
                defaultValue={5}
                control={form.control}
                name="rating"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="flex flex-col gap-2">
                    <Rating
                      name="Quality Rating"
                      value={value}
                      size="large"
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                    />
                    {error && (
                      <p className="text-red-500 text-sm">{error.message}</p>
                    )}
                  </div>
                )}
              />
            </div>
            <Controller
              control={form.control}
              name="signatory"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AppCameraFieldFile
                  onDeleteUploadedPhoto={async () => {
                    onChange({
                      ...value,
                      uploadedSignatory: undefined,
                    });
                    onDeleteSignatory(Number(workOrderId));
                  }}
                  uploadedPhotos={
                    value?.uploadedSignatory ? [value.uploadedSignatory] : []
                  }
                  label="Signatory"
                  onChange={(files) => {
                    onChange({
                      ...value,
                      currentSignatory: files[0],
                    });
                  }}
                  onDelete={() => {
                    if (!value) return;
                    onChange({
                      ...value,
                      currentSignatory: undefined,
                    });
                  }}
                  photos={value?.currentSignatory ? [value.currentSignatory] : []}
                  error={!!error}
                  helperText={
                    form.formState.errors.signatory?.message !== ""
                      ? "At least one photo required"
                      : ""
                  }
                />
              )}
            />
            <AppTextField
              label="Signed By"
              name="signedBy"
              placeholder="Enter signed by..."
            />
            <div className="flex justify-end gap-2">
              <AppButton
                type="button"
                variant="outline"
                label="Cancel"
                onClick={onClose}
              />
              <AppButton
                onClick={form.handleSubmit(onSubmit)}
                label="Save"
                isLoading={isPending}
              />
            </div>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
} 