import { ChartTooltipContent } from "../../../../components/ui/chart";
import { ChartTooltip } from "../../../../components/ui/chart";
import { ChartContainer } from "../../../../components/ui/chart";
import { useSearchParams } from "react-router-dom";
import { Project } from "@cerev-cmms/prisma";
import { DateFilter } from "../WorkRequestAnalytics/DateFilter";
import WorkRequestTrend from "../WorkRequestAnalytics/WorkRequestTrend";
import { trpc } from "../../../../lib/trpc";
import { Card, CardContent,  CardHeader, CardDescription, CardTitle } from "../../../../components/ui/card";
import {  Loader2 } from "lucide-react";
import { Area, CartesianGrid, XAxis, AreaChart } from "recharts";
import { YAxis } from "recharts";

export function CCWorkRequestTrend({project}: {project: Project}) {
    const [searchParams] = useSearchParams();
    const year = searchParams.get("year")
      ? parseInt(searchParams.get("year")!)
      : undefined;
    const month = searchParams.get("month")
      ? parseInt(searchParams.get("month")!)
      : undefined;
  
    const { data, isLoading } =
      trpc.analytics.getWorkRequestSubmissionTrend.useQuery(
        {
          projectId: project?.id ?? 0,
          year,
          month,
        },
        {
          enabled: !!project,
        }
      );
  
    if (isLoading) {
      return (
        <Card>
          <CardContent className="pt-6">
            <div className="w-full min-h-[275px] flex items-center justify-center">
              <Loader2 className="h-12 w-12 animate-spin text-primary" />
            </div>
          </CardContent>
        </Card>
      );
    }
  
    if (!data || data.chartData.length === 0) {
      return (
        <Card>
          <CardContent className="pt-6">
            <div className="w-full min-h-[275px] flex items-center justify-center">
              No work request data available for the selected period
            </div>
          </CardContent>
        </Card>
      );
    }
  
    return (
      <Card>
        <CardHeader>
          <CardTitle>{project.name}</CardTitle>
          <CardDescription>
            This chart shows the trend of work requests created per month.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <ChartContainer
            config={data.chartConfig}
            className="mx-auto aspect-square max-h-[300px] w-full"
          >
            <AreaChart
              data={data.chartData}
              accessibilityLayer
              margin={{ left: 0, right: 12, top: 20, bottom: 20 }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
              />
              <YAxis tickLine={false} axisLine={false} tickMargin={8} />
              <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
              <Area
                type="monotone"
                dataKey="count"
                name={data.chartConfig.count.label}
                stroke={data.chartConfig.count.color}
                fill={data.chartConfig.count.color}
                fillOpacity={0.3}
              />
            </AreaChart>
          </ChartContainer>
      </CardContent>
    </Card>
  );
}
