import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { trpc } from "../../../lib/trpc";
import AppTitle from "../../HomeScreen/components/AppTitle";
import { CCMultipleWorkRequestTrend } from "./CommandCentreGraphs/CCMultipleWorkRequestTrend";
import { CCWorkRequestKpiAvgTurnAroundTime } from "./CommandCentreGraphs/CCWorkRequestKpiAvgTurnAroundTime";
import { CCWorkRequestStatusBreakdownPieChart } from "./CommandCentreGraphs/CCWorkRequestStatusBreakdownPieChart";
import { CCWorkRequestTrend } from "./CommandCentreGraphs/CCWorkRequestTrend";
import { DateFilter } from "./WorkRequestAnalytics/DateFilter";
import { Clock, AlertCircle } from "lucide-react";

export default function CCDashboard() {
  const projects = trpc.analytics.getProjectsUnderRCI.useQuery();

  return (
    <>
      <AppTitle title="Work Requests" />
      <Tabs defaultValue="0">
        <TabsList>
          <TabsTrigger value="0">
            <p>Trends</p>
          </TabsTrigger>
          <TabsTrigger value="1">
            <p>KPIs</p>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          <div className="flex items-center justify-between my-4">
            <DateFilter />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-3">
              <CCMultipleWorkRequestTrend projects={projects.data ?? []} />
            </div>
            {projects.data?.map((project) => (
              <CCWorkRequestTrend key={project.id} project={project} />
            ))}
          </div>
        </TabsContent>
        <TabsContent value="1">
          <div className="flex items-center justify-between my-4">
            <DateFilter />
          </div>

          <div className="bg-gradient-to-r from-blue-50 to-blue-100 border-l-4 border-blue-600 rounded-lg p-6 mb-8 shadow-sm">
            <div className="flex gap-4">
              <div className="flex items-center justify-center bg-blue-100 rounded-full p-2">
                <AlertCircle className="h-6 w-6 text-blue-700" />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <h2 className="text-lg font-bold text-blue-900">
                    Target Response Time
                  </h2>
                  <div className="bg-blue-200 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                    KPI 1
                  </div>
                </div>
                <p className="text-sm text-blue-800 mt-1">
                  Work requests must be closed within{" "}
                  <span className="font-semibold">48 hours</span> from creation
                </p>
                <div className="flex gap-4 mt-3">
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 bg-green-400 rounded-full"></div>
                    <span className="text-xs text-blue-800">Good: &lt;24h</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 bg-yellow-400 rounded-full"></div>
                    <span className="text-xs text-blue-800">
                      Warning: 24-48h
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 bg-red-400 rounded-full"></div>
                    <span className="text-xs text-blue-800">
                      Critical: &gt;48h
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            {projects.data?.map((project) => (
              <CCWorkRequestKpiAvgTurnAroundTime
                key={project.id}
                project={project}
              />
            ))}
          </div>

          <div className="bg-gradient-to-r from-blue-50 to-blue-100 border-l-4 border-blue-600 rounded-lg p-6 my-8 shadow-sm">
            <div className="flex gap-4">
              <div className="flex items-center justify-center bg-blue-100 rounded-full p-2">
                <AlertCircle className="h-6 w-6 text-blue-700" />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <h2 className="text-lg font-bold text-blue-900">
                    Work Request Completion Rate
                  </h2>
                  <div className="bg-blue-200 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                    KPI 2
                  </div>
                </div>
                <p className="text-sm text-blue-800 mt-1">
                  All sites must maintain a <span className="font-semibold">minimum 80%</span> work request completion rate
                </p>
                <div className="flex gap-4 mt-3">
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 bg-green-400 rounded-full"></div>
                    <span className="text-xs text-blue-800">Good: ≥80%</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 bg-yellow-400 rounded-full"></div>
                    <span className="text-xs text-blue-800">
                      Warning: 50-79%
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 bg-red-400 rounded-full"></div>
                    <span className="text-xs text-blue-800">
                      Critical: &lt;50%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {projects.data?.map((project) => (
              <CCWorkRequestStatusBreakdownPieChart
                key={project.id}
                project={project}
              />
            ))}
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
}
