import { useAppSelector } from "../../../../redux/store";
import { ListOfFmContracts } from "./ListOfFmContracts/ListOfFmContracts";
import { ContractPieChart } from "./ContractPieChart";
import { FmStatutoryMetrics } from "./FmStatutoryMetrics";
import { ListOfExpiredFmContracts } from "./ListOfExpiredFmContracts/ListOfExpiredFmContracts";
import { ListOfExpiredStatutoryContracts } from "./ListOfExpiredStatutoryContracts/ListOfExpiredStatutoryContracts";

export default function OasisSquareLegalComplianceAnalytics() {
  const activeProject = useAppSelector((state) => state.root.activeProject);

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Legal Compliance Overview</h2>
      <FmStatutoryMetrics />
      <ContractPieChart />
      <ListOfExpiredFmContracts />
      <ListOfExpiredStatutoryContracts />
    </div>
  );
}
