import { useFormContext, useWatch } from "react-hook-form";
import { useMemo } from "react";
import { cn } from "../../../lib/utils";
import Decimal from "decimal.js";

interface FormValues {
  kpiRows: {
    target: string;
    actual: string;
    demeritPoint: string;
    [key: string]: any;
  }[];
}

export function TotalDemeritPointsImposed() {
  const { control } = useFormContext<FormValues>();

  const kpiRows = useWatch({
    control,
    name: "kpiRows",
  });

  const totalDemeritPointsImposed = useMemo(() => {
    try {
      return kpiRows.reduce((sum, row) => {
        const actualValue = parseFloat(row.actual || '0');
        const targetValue = parseFloat(row.target || '0');
        const demeritPointValue = parseFloat(row.demeritPoint || '0');

        // Only add to sum if actual is less than target
        return sum + (actualValue < targetValue ? demeritPointValue : 0);
      }, 0);
    } catch (error) {
      return 0;
    }
  }, [kpiRows]);

  return (
    <td className="border p-2 text-sm text-right font-bold">
      {totalDemeritPointsImposed}
    </td>
  );
} 