import {
  Files,
  MapPin,
  MessageSquare,
  Phone,
  Settings,
  Tag,
  User2,
} from "lucide-react";
import { useSearchParams } from "react-router-dom";
import AppButton from "../../../AppButton";
import AppDocument from "../../../AppDocument";
import AppItemInfo from "../../../AppItemInfo";
import ConfirmDeleteBtnWithDialog from "../../../dialogs/ConfirmDeleteWithTextDialog";
import { DRAWER_UPDATE_STATE, VENDOR_FORM_DRAWER } from "../../AppDrawer";
import { trpc } from "../../../../lib/trpc";
import type { RouterOutputs } from "@cerev-cmms/trpc";

type VendorInfoTabProps = {
  vendor: RouterOutputs["vendor"]["getOneVendor"];
};

export function VendorInfoTab({ vendor }: VendorInfoTabProps) {
  const utils = trpc.useUtils();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: deleteVendor } = trpc.vendor.deleteVendor.useMutation({
    onSuccess: () => {
      utils.vendor.getVendors.invalidate();
      setSearchParams((p) => {
        p.delete("drawerType");
        p.delete("drawerState");
        p.delete("vendorId");
        return p;
      });
    },
  });

  return (
    <div className="flex flex-col gap-6 mt-6">
      <AppItemInfo
        label="Name"
        content={<p className="font-sans">{vendor?.name ?? "-"}</p>}
        icon={<Tag className="text-primary-900" />}
      />
      <AppItemInfo
        label="Work Scopes"
        content={
          <p className="font-sans">
            {vendor?.workscope.map((sc) => sc.name).join(", ") ?? "-"}
          </p>
        }
        icon={<Settings className="text-primary-900" />}
      />
      <AppItemInfo
        label="Contact Person"
        content={<p className="font-sans">{vendor?.contactName ?? "-"}</p>}
        icon={<User2 className="text-primary-900" />}
      />
      <AppItemInfo
        label="Contact No."
        content={<p className="font-sans">{vendor?.contactNo ?? "-"}</p>}
        icon={<Phone className="text-primary-900" />}
      />
      <AppItemInfo
        label="Business Address"
        content={<p className="font-sans">{vendor?.businessAddress ?? "-"}</p>}
        icon={<MapPin className="text-primary-900" />}
      />
      <AppItemInfo
        label="Remarks"
        content={<p className="font-sans">{vendor?.remarks ?? "-"}</p>}
        icon={<MessageSquare className="text-primary-900" />}
      />
      <AppItemInfo
        label="Additional Documents"
        content={
          <div className="flex flex-col gap-4">
            {vendor?.attachments?.length ? (
              vendor?.attachments.map((p) => (
                <AppDocument
                  key={p.id}
                  att={{
                    ...p,
                    createdById: p.createdById ?? undefined,
                    updatedById: p.updatedById ?? undefined,
                    deletedById: p.deletedById ?? undefined,
                    createdOn: p.createdOn.toISOString(),
                    deletedOn: p.deletedOn?.toISOString() ?? undefined,
                    status: p.status ?? undefined,
                    fileSizeMb: p.fileSizeMb?.toString() ?? undefined,
                  }}
                />
              ))
            ) : (
              <p className="font-sans text-neutral-900">-</p>
            )}
          </div>
        }
        icon={<Files className="text-primary-900" />}
      />
      <div className="flex gap-4">
        <AppButton
          variant="outline"
          label="Edit"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", VENDOR_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("vendorId", vendor?.id.toString() ?? "");
              return p;
            });
          }}
        />
        <ConfirmDeleteBtnWithDialog
          confirmDeleteTxt={vendor?.name ?? ""}
          onDeleteConfirm={async () => {
            if (vendor) {
              deleteVendor(vendor.id.toString());
            }
          }}
        />
      </div>
    </div>
  );
} 