import { ChartTooltipContent } from "../../../../components/ui/chart";
import { ChartTooltip } from "../../../../components/ui/chart";
import { ChartContainer } from "../../../../components/ui/chart";
import { useSearchParams } from "react-router-dom";
import { Project } from "@cerev-cmms/prisma";
import { trpc } from "../../../../lib/trpc";
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from "../../../../components/ui/card";
import { Loader2 } from "lucide-react";
import { Area, CartesianGrid, XAxis, AreaChart, YAxis } from "recharts";

// Array of colors for different projects
const CHART_COLORS = [
  "#2563eb", // blue
  "#16a34a", // green
  "#dc2626", // red
  "#9333ea", // purple
  "#ea580c", // orange
  "#0891b2", // cyan
  "#4f46e5", // indigo
  "#be185d", // pink
];

export function CCMultipleWorkOrderTrend({ projects }: { projects: Project[] }) {
  const [searchParams] = useSearchParams();
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;

  const { data, isLoading } = trpc.analytics.getMultipleProjectWOTrend.useQuery(
    {
      projectIds: projects.map(p => p.id),
      year,
      month,
    },
    {
      enabled: projects.length > 0,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No work order data available for the selected period
          </div>
        </CardContent>
      </Card>
    );
  }

  // Transform the data for stacked area chart
  const transformedData = data.chartData[0].data.map((item: any, index: number) => {
    const dataPoint: any = { date: item.date };
    data.chartData.forEach((project: any, projectIndex: number) => {
      dataPoint[project.name] = project.data[index].count;
    });
    return dataPoint;
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Work Order Trends Comparison</CardTitle>
        <CardDescription>
          This chart shows the comparison of work orders created across multiple projects.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[300px] w-full"
        >
          <AreaChart
            data={transformedData}
            accessibilityLayer
            margin={{ left: 40, right: 40, top: 20, bottom: 40 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={true}
              tick={{ fill: 'hsl(var(--foreground))', fontSize: 12 }}
              tickMargin={8}
            />
            <YAxis 
              tickLine={false}
              axisLine={true}
              tick={{ fill: 'hsl(var(--foreground))', fontSize: 12 }}
              tickMargin={8}
            />
            <ChartTooltip 
              cursor={false} 
              content={
                <ChartTooltipContent 
                  style={{
                    backgroundColor: 'hsl(var(--background))',
                    border: '1px solid hsl(var(--border))',
                    borderRadius: '6px',
                    padding: '8px 12px',
                  }}
                />
              }
            />
            {data.chartData.map((project, index) => (
              <Area
                key={project.name}
                type="monotone"
                dataKey={project.name}
                name={project.name}
                stroke={`hsl(var(--chart-${(index % 5) + 1}))`}
                fill={`hsl(var(--chart-${(index % 5) + 1}))`}
                fillOpacity={0.3}
                stackId="1"
              />
            ))}
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
