import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import { useMemo, useState } from "react";
import type { RouterOutputs } from "@cerev-cmms/trpc";
import AppButton from "../../../AppButton";
import { Plus } from "lucide-react";
import {
  DRAWER_CREATE_STATE,
  DRAWER_UPDATE_STATE,
  PRODUCT_FORM_DRAWER,
} from "../../AppDrawer";
import AppTextField from "../../../AppTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProductDetailDialog } from "./ProductDetailDialog";

type Product = RouterOutputs["vendor"]["getProducts"]["data"][number];

export function ProductsTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const vendorId = searchParams.get("vendorId") ?? "";
  const methods = useForm<{ search: string }>();
  const search = methods.watch("search");
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

  const {
    data: productsData,
    isLoading,
    isFetching,
  } = trpc.vendor.getProducts.useQuery(
    {
      vendorId,
      search,
    },
    {
      enabled: !!vendorId,
    }
  );

  const handleRowClick = (product: Product) => {
    setSelectedProductId(product.id.toString());
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-start"></div>

      <FormProvider {...methods}>
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <AppTextField name="search" placeholder="Search products..." />
            <AppButton
              label="Create Product"
              icon={<Plus className="w-4 h-4" />}
              onClick={() => {
                setSearchParams((params) => {
                  params.set("drawerType", PRODUCT_FORM_DRAWER);
                  params.set("drawerState", DRAWER_CREATE_STATE);
                  params.set("vendorId", vendorId);
                  return params;
                });
              }}
            />
          </div>

          <div className="rounded-xl bg-neutral-50 flex flex-col divide-y">
            {isLoading ? (
              <div className="p-4 text-center text-gray-500">Loading...</div>
            ) : productsData?.data && productsData.data.length > 0 ? (
              productsData.data.map((product) => (
                <div
                  key={product.id}
                  onClick={() => handleRowClick(product)}
                  className="p-4 hover:bg-neutral-100 cursor-pointer transition-colors"
                >
                  <div className="flex flex-col gap-1">
                    <div className="font-medium">{product.name}</div>
                    <div className="text-sm text-gray-500">
                      {product.description || "-"}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-gray-500">
                No products found
              </div>
            )}
          </div>
        </div>
      </FormProvider>

      {selectedProductId && (
        <ProductDetailDialog
          open={!!selectedProductId}
          onOpenChange={(open) => {
            if (!open) setSelectedProductId(null);
          }}
          productId={selectedProductId}
        />
      )}
    </div>
  );
}
