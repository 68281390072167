export const kpiDataDefault = [
  {
    no: "1",
    kpi: "FMM SERVICE DELIVERY RELATED TO CORE BUSINESS",
    piNo: "1A",
    performanceIndicator: "CUSTOMER SATISFACTION SURVEY RATING (CSS)",
    target: "80",
    actual: "83.31",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "6",
    apdValue: "423.66",
    apdValueDeducted: "0.00",
  },
  {
    no: "1",
    kpi: "",
    piNo: "1B",
    performanceIndicator: "CUSTOMER RATING IN WORK ORDER SHEET",
    target: "70",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "6",
    apdValue: "423.66",
    apdValueDeducted: "0.00",
  },
  {
    no: "1",
    kpi: "",
    piNo: "1C",
    performanceIndicator: "RESPONSE TIME",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "7",
    apdValue: "494.27",
    apdValueDeducted: "0.00",
  },
  {
    no: "1",
    kpi: "",
    piNo: "1D",
    performanceIndicator: "EXECUTE TIME",
    target: "95",
    actual: "100",
    demeritPoint: "2",
    demeritPointsImposed: "0",
    weightage: "7",
    apdValue: "494.27",
    apdValueDeducted: "0.00",
  },
  {
    no: "1",
    kpi: "",
    piNo: "1E",
    performanceIndicator: "PENDING / BACKLOG WORK ORDER",
    target: "100",
    actual: "100",
    demeritPoint: "2",
    demeritPointsImposed: "0",
    weightage: "8",
    apdValue: "564.88",
    apdValueDeducted: "0.00",
  },
  {
    no: "1",
    kpi: "",
    piNo: "1F",
    performanceIndicator:
      "SELF-FINDING WORK ORDERS > 80% FROM TOTAL WORK ORDER",
    target: "80",
    actual: "99.22",
    demeritPoint: "2",
    demeritPointsImposed: "0",
    weightage: "10",
    apdValue: "706.10",
    apdValueDeducted: "0.00",
  },
  {
    no: "1",
    kpi: "",
    piNo: "1G",
    performanceIndicator: "CRITICAL SERVICES",
    target: "95",
    actual: "100",
    demeritPoint: "2",
    demeritPointsImposed: "0",
    weightage: "8",
    apdValue: "564.88",
    apdValueDeducted: "0.00",
  },
  {
    no: "1",
    kpi: "",
    piNo: "1H",
    performanceIndicator: "NORMAL SERVICES",
    target: "85",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "7",
    apdValue: "494.27",
    apdValueDeducted: "0.00",
  },
  {
    no: "2",
    kpi: "ASSET PERFORMANCE",
    piNo: "2A",
    performanceIndicator: "PPM FOR ARCHITECTURE AND CIVIL ASSETS IMPLEMENTED",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "4",
    apdValue: "282.44",
    apdValueDeducted: "0.00",
  },
  {
    no: "2",
    kpi: "",
    piNo: "2B",
    performanceIndicator: "PPM FOR MECHANICAL ASSETS IMPLEMENTED",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "4",
    apdValue: "282.44",
    apdValueDeducted: "0.00",
  },
  {
    no: "2",
    kpi: "",
    piNo: "2C",
    performanceIndicator: "PPM FOR ELECTRICAL ASSETS IMPLEMENTED",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "4",
    apdValue: "282.44",
    apdValueDeducted: "0.00",
  },
  {
    no: "2",
    kpi: "",
    piNo: "2D",
    performanceIndicator:
      "ENGINEERING/SYSTEM REPORT & RECOMMENDATION ACTION TAKEN",
    target: "100",
    actual: "100",
    demeritPoint: "2",
    demeritPointsImposed: "0",
    weightage: "4",
    apdValue: "282.44",
    apdValueDeducted: "0.00",
  },
  {
    no: "2",
    kpi: "",
    piNo: "2E",
    performanceIndicator:
      "WORK DONE AS SPECIFICATION AND MANUFACTURER'S MERIT STANDARDS",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "4",
    apdValue: "282.44",
    apdValueDeducted: "0.00",
  },
  {
    no: "3",
    kpi: "BUILDING ENERGY EFFICIENCY",
    piNo: "3A",
    performanceIndicator: "ENERGY CONSERVATION PROGRAMME IMPLEMENTED",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "4",
    apdValue: "282.44",
    apdValueDeducted: "0.00",
  },
  {
    no: "3",
    kpi: "",
    piNo: "3B",
    performanceIndicator: "BUILDING ENERGY INDEX (BEI) TARGET MET",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "3",
    apdValue: "211.83",
    apdValueDeducted: "0.00",
  },
  {
    no: "3",
    kpi: "",
    piNo: "3C",
    performanceIndicator: "UTILITY CONSUMPTION NO WASTAGE",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "3",
    apdValue: "211.83",
    apdValueDeducted: "0.00",
  },
  {
    no: "4",
    kpi: "SAFETY & STATUTORY COMPLIANCE",
    piNo: "4A",
    performanceIndicator: "RELEVANT ACTS & REGULATIONS COMPLIANCE",
    target: "100",
    actual: "100",
    demeritPoint: "2",
    demeritPointsImposed: "0",
    weightage: "4",
    apdValue: "282.44",
    apdValueDeducted: "0.00",
  },
  {
    no: "4",
    kpi: "",
    piNo: "4B",
    performanceIndicator: "HSE PLAN IMPLEMENTED",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "3",
    apdValue: "211.83",
    apdValueDeducted: "0.00",
  },
  {
    no: "4",
    kpi: "",
    piNo: "4C",
    performanceIndicator: "REPORTS SUBMITTED ON TIME WITH SUFFICIENT CONTENT",
    target: "100",
    actual: "100",
    demeritPoint: "1",
    demeritPointsImposed: "0",
    weightage: "4",
    apdValue: "282.44",
    apdValueDeducted: "0.00",
  },
];
