import { Controller, useFormContext, useWatch } from "react-hook-form";
import { WorkOrderForm } from ".";
import AppCameraFieldFilesTRPC from "../../../AppCameraFieldFilesTRPC";
import { RouterOutputs } from "@cerev-cmms/trpc";

type Attachment = RouterOutputs["workOrders"]["getOneWorkOrder"]["photos"][number];

export default function WOAttachmentField() {
  const methods = useFormContext<WorkOrderForm>();
  const uploadedPhotos = useWatch({
    control: methods.control,
    name: "linkedWRAttachments",
  });
  return (
    <Controller
      control={methods.control}
      name="attachments"
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <AppCameraFieldFilesTRPC
            uploadedPhotos={uploadedPhotos as Attachment[]}
            onDeleteUploadedPhoto={async (a) => {
              methods.setValue(
                "linkedWRAttachments",
                uploadedPhotos?.filter((p) => p.id !== a.id)
              );
            }}
            label="Photos"
            onChange={onChange}
            onDelete={(url) => {
              if (!value) return;
              const newSetPhotos = value.filter((v) => v !== url);
              onChange(newSetPhotos);
            }}
            photos={value ?? []}
            error={!!error}
            helperText={
              methods.formState.errors.attachments?.message !== ""
                ? "At least one photo required"
                : ""
            }
          />
        );
      }}
    />
  );
}
