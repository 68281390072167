import { useSearchParams } from "react-router-dom";
import {
  usePmTemplateControllerDeletePmTemplate,
  usePmTemplateControllerGetOnePmTemplate,
  usePmTemplateControllerGetPmTemplate,
} from "../../../../api/preventive-maintenance-template/preventive-maintenance-template";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import AppItemInfo from "../../../AppItemInfo";
import {
  AlignLeft,
  Calendar,
  Clock,
  Hash,
  Image,
  ListRestart,
  Layout,
  Tag,
  Tags,
  ToggleLeft,
} from "lucide-react";
import useDate from "../../../../hooks/useDate";
import AppButton from "../../../AppButton";
import ConfirmDeleteBtnWithDialog from "../../../dialogs/ConfirmDeleteWithTextDialog";
import { useQueryClient } from "@tanstack/react-query";
import useError from "../../../../hooks/useError";
import { DRAWER_UPDATE_STATE, PM_TEMPLATE_FORM_DRAWER } from "../../AppDrawer";
import { usePmChecklistControllerCreatePmChecklist } from "../../../../api/preventive-maintenance-checklists/preventive-maintenance-checklists";
import { useAuthControllerGetCurrentUser } from "../../../../api/auth/auth";
import { useAppSelector } from "../../../../redux/store";
import { useToast } from "../../../ui/use-toast";
import ExportCsv from "./components/ExportCsv";
import FrequencySettingDescription from "./components/FrequencySettingDescription";
import { trpc } from "../../../../lib/trpc";
import { AssetListDialog } from "../../../dialogs/AssetListDialog";
import { useState } from "react";

export default function PmTemplateDetailDrawer() {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const pmTemplateId = searchParams.get("pmTemplateId");
  const { formatDateToLocal } = useDate();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { toast } = useToast();
  const { data: user } = trpc.user.me.useQuery();
  const [assetDialogOpen, setAssetDialogOpen] = useState(false);

  const { data: pmTemplate, isLoading } = trpc.pm.getSinglePmTemplate.useQuery(
    { pmTemplateId: pmTemplateId ?? "" },
    {
      enabled: !!pmTemplateId,
      select: (res) => {
        const sortedTemplates = [...(res?.pmFormat ?? [])].sort(
          (a, b) => a.order - b.order
        );
        return { ...res, pmFormat: sortedTemplates };
      },
    }
  );

  const { mutate: createPmChecklist, isPending: createPmChecklistIsPending } =
    trpc.pm.createPmChecklist.useMutation({
      onSuccess: () => {
        toast({
          title: "Success",
          description: "PM Checklist created successfully",
        });
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as string).includes("pm-checklist");
          },
        });
      },
    });

  const {
    mutateAsync: deletePmTemplate,
    isError: deletePmTemplateIsError,
    error: deletePmTemplateError,
  } = usePmTemplateControllerDeletePmTemplate({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as string).includes("pm-template");
          },
        });

        // Navigate back to the list page
        const newParam = new URLSearchParams();
        setSearchParams(newParam);
      },
    },
  });

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-6">
      <p className="font-sans text-2xl font-bold">PM Template Detail</p>
      <AppItemInfo
        label="Name"
        content={<p className="font-sans">{pmTemplate?.name ?? "-"}</p>}
        icon={<Tag className="text-primary-900" />}
      />
      <AppItemInfo
        label="Template Type"
        content={
          <p className="font-sans">
            {pmTemplate?.pmTemplateType === "ONE_EQUIPMENT"
              ? "One Equipment"
              : "Multiple Equipment"}
          </p>
        }
        icon={<Layout className="text-primary-900" />}
      />
      <AppItemInfo
        label="Frequency"
        content={
          //   Temporary use this fix to hide the 12pm implementation time.
          // To plan out for more advance PM usage
          // <p>
          //   {pmTemplate?.data?.frequency
          //     ? construe
          //         .toString(pmTemplate?.data?.frequency, {
          //           use24HourTimeFormat: true,
          //         })
          //         .replace("At 12:00,", "")
          //     : "-"}
          // </p>
          <FrequencySettingDescription />
        }
        icon={<Clock className="text-primary-900" />}
      />
      <AppItemInfo
        label="Start Date"
        content={
          <p className="font-sans">
            {pmTemplate?.frequencySetting?.dateStart
              ? formatDateToLocal({
                  dateStr:
                    pmTemplate?.frequencySetting?.dateStart.toISOString(),
                  formatting: "d MMMM yyyy",
                })
              : "-"}
          </p>
        }
        icon={<Calendar className="text-primary-900" />}
      />
      {/* <AppItemInfo
        label="End Date"
        content={
          <p className="font-sans">
            {pmTemplate?.data?.dateEnd
              ? formatDateToLocal({ dateStr: pmTemplate?.data?.dateEnd })
              : "-"}
          </p>
        }
        icon={<Calendar className="text-primary-900" />}
      /> */}
      <AppItemInfo
        label="Equipments"
        content={
          <>
            <div>
              <AppButton
                variant="outline"
                onClick={() => setAssetDialogOpen(true)}
                label={`${pmTemplate?.asset?.length ?? 0} Equipment`}
              />
            </div>
            <AssetListDialog
              isOpen={assetDialogOpen}
              onClose={() => setAssetDialogOpen(false)}
              assets={pmTemplate?.asset ?? []}
            />
          </>
        }
        icon={<Tags className="text-primary-900" />}
      />
      <p className="font-medium text-xl">Fields</p>
      {pmTemplate?.pmFormat?.map((ft) => {
        switch (ft.pmFormatType.name) {
          case "BOOL":
            return (
              <AppItemInfo
                key={ft.id}
                label="Yes / No"
                content={<p className="font-sans">{ft.name}</p>}
                icon={<ToggleLeft className="text-primary-900" />}
              />
            );
          case "VARCHAR":
            return (
              <AppItemInfo
                key={ft.id}
                label="Text"
                content={<p className="font-sans">{ft.name}</p>}
                icon={<AlignLeft className="text-primary-900" />}
              />
            );
          case "NUMBER":
            return (
              <AppItemInfo
                key={ft.id}
                label="Number"
                content={<p className="font-sans">{ft.name}</p>}
                icon={<Hash className="text-primary-900" />}
              />
            );
          case "ATTACHMENT":
            return (
              <AppItemInfo
                key={ft.id}
                label="Image"
                content={<p className="font-sans">{ft.name}</p>}
                icon={<Image className="text-primary-900" />}
              />
            );
        }
      })}

      <div className="flex gap-4">
        <AppButton
          className="flex-grow"
          label="Generate"
          variant="outline"
          isLoading={createPmChecklistIsPending}
          onClick={() => {
            createPmChecklist({
              pmTemplateId: +(pmTemplateId as string),
              projectId: activeProj?.id ?? 0,
              assetIds: pmTemplate?.asset.map((asset) => asset.id) ?? [],
            });
          }}
          icon={<ListRestart />}
        />
        <ExportCsv />
      </div>
      <div className="flex gap-2">
        <AppButton
          label="Update"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", PM_TEMPLATE_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("pmTemplateId", pmTemplate?.id?.toString() ?? "");
              return p;
            });
          }}
        />
        <ConfirmDeleteBtnWithDialog
          confirmDeleteTxt={pmTemplate?.name ?? ""}
          onDeleteConfirm={async () => {
            deletePmTemplate({
              pmTemplateId: +(pmTemplateId as string),
            });
          }}
        />
      </div>
    </div>
  );
}
