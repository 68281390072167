import React, { useEffect, useRef } from "react";
import { Card } from "@/components/ui/card";
import { format, addMonths, subMonths } from "date-fns";
import { trpc } from "../../../lib/trpc";
import { KpiCard } from "./KpiCard";
import { useReactToPrint } from "react-to-print";
import AppButton from "../../../components/AppButton";
import { Printer, ChevronLeft, ChevronRight, Calculator } from "lucide-react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";

interface AnalyticsValue {
  kpiRows: {
    piNo: string;
    performanceIndicator: string;
    actual: string;
    target: string;
  }[];
}

interface KpiData {
  code: string;
  description: string;
  value: number;
  threshold: number;
}

export default function JkrKpiReport() {
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProject = useAppSelector((state) => state.root.activeProject);

  // Get the date from URL or default to current month
  const dateParam = searchParams.get("date");
  const currentDate = dateParam ? new Date(dateParam) : new Date();

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const { data: analyticsData, isLoading } =
    trpc.pdfReports.getOneCustomProjectAnalytic.useQuery({
      projectId: activeProject?.id?.toString() ?? "",
      name: "JKR_APD",
      dateStart: firstDayOfMonth.toISOString(),
      dateEnd: lastDayOfMonth.toISOString(),
    });

  const upsertMutation =
    trpc.pdfReports.upsertCustomProjectAnalytic.useMutation({
      onSuccess: () => {
        utils.pdfReports.getOneCustomProjectAnalytic.invalidate();
      },
    });

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    pageStyle: "@page { size: auto; margin: 0.5in; }",
    copyShadowRoots: true,
  });

  const handleMonthChange = (direction: "prev" | "next") => {
    const newDate =
      direction === "prev"
        ? subMonths(currentDate, 1)
        : addMonths(currentDate, 1);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("date", newDate.toISOString());
    setSearchParams(newSearchParams);
  };

  // Get KPI values from the APD data
  const kpiRows = (analyticsData?.data?.value as unknown as AnalyticsValue)?.kpiRows ?? [];

  const kpis: KpiData[] = [
    {
      code: "KPI 1A",
      description: "Customer Satisfaction Survey Rating (CSS)",
      value: parseFloat(kpiRows.find(row => row.piNo === "1A")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "1A")?.target ?? "0"),
    },
    {
      code: "KPI 1B",
      description: "Customer Rating in Work Order Sheet",
      value: parseFloat(kpiRows.find(row => row.piNo === "1B")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "1B")?.target ?? "0"),
    },
    {
      code: "KPI 1C",
      description: "Response Time",
      value: parseFloat(kpiRows.find(row => row.piNo === "1C")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "1C")?.target ?? "0"),
    },
    {
      code: "KPI 1D",
      description: "Execute Time",
      value: parseFloat(kpiRows.find(row => row.piNo === "1D")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "1D")?.target ?? "0"),
    },
    {
      code: "KPI 1E",
      description: "Pending / Backlog Work Order",
      value: parseFloat(kpiRows.find(row => row.piNo === "1E")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "1E")?.target ?? "0"),
    },
    {
      code: "KPI 1F",
      description: "Self-Finding Work Orders > 80% from Total Work Order",
      value: parseFloat(kpiRows.find(row => row.piNo === "1F")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "1F")?.target ?? "0"),
    },
    {
      code: "KPI 1G",
      description: "Critical Services",
      value: parseFloat(kpiRows.find(row => row.piNo === "1G")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "1G")?.target ?? "0"),
    },
    {
      code: "KPI 1H",
      description: "Normal Services",
      value: parseFloat(kpiRows.find(row => row.piNo === "1H")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "1H")?.target ?? "0"),
    },
    {
      code: "KPI 2A",
      description: "PPM For Architecture and Civil Assets Implemented",
      value: parseFloat(kpiRows.find(row => row.piNo === "2A")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "2A")?.target ?? "0"),
    },
    {
      code: "KPI 2B",
      description: "PPM For Mechanical Assets Implemented",
      value: parseFloat(kpiRows.find(row => row.piNo === "2B")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "2B")?.target ?? "0"),
    },
    {
      code: "KPI 2C",
      description: "PPM For Electrical Assets Implemented",
      value: parseFloat(kpiRows.find(row => row.piNo === "2C")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "2C")?.target ?? "0"),
    },
    {
      code: "KPI 2D",
      description: "Engineering/System Report & Recommendation Action Taken",
      value: parseFloat(kpiRows.find(row => row.piNo === "2D")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "2D")?.target ?? "0"),
    },
    {
      code: "KPI 2E",
      description: "Work Done as Specification and Manufacturer's Merit Standards",
      value: parseFloat(kpiRows.find(row => row.piNo === "2E")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "2E")?.target ?? "0"),
    },
    {
      code: "KPI 3A",
      description: "Energy Conservation Programme Implemented",
      value: parseFloat(kpiRows.find(row => row.piNo === "3A")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "3A")?.target ?? "0"),
    },
    {
      code: "KPI 3B",
      description: "Building Energy Index (BEI) Target Met",
      value: parseFloat(kpiRows.find(row => row.piNo === "3B")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "3B")?.target ?? "0"),
    },
    {
      code: "KPI 3C",
      description: "Utility Consumption No Wastage",
      value: parseFloat(kpiRows.find(row => row.piNo === "3C")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "3C")?.target ?? "0"),
    },
    {
      code: "KPI 4A",
      description: "Relevant Acts & Regulations Compliance",
      value: parseFloat(kpiRows.find(row => row.piNo === "4A")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "4A")?.target ?? "0"),
    },
    {
      code: "KPI 4B",
      description: "HSE Plan Implemented",
      value: parseFloat(kpiRows.find(row => row.piNo === "4B")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "4B")?.target ?? "0"),
    },
    {
      code: "KPI 4C",
      description: "Reports Submitted on Time with Sufficient Content",
      value: parseFloat(kpiRows.find(row => row.piNo === "4C")?.actual ?? "0"),
      threshold: parseFloat(kpiRows.find(row => row.piNo === "4C")?.target ?? "0"),
    },
  ];

  const achievedKpis = kpis.filter((kpi) => kpi.value >= kpi.threshold).length;
  const failedKpis = kpis.length - achievedKpis;

  const calculateMutation = trpc.analytics.getJkrKpiReport.useMutation({
    onSuccess: (data) => {
      utils.pdfReports.getOneCustomProjectAnalytic.invalidate();
    },
  });

  const handleAutoCalculate = () => {
    if (!activeProject?.id) return;

    calculateMutation.mutate({
      projectId: activeProject.id,
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    });
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="p-8">
      <div className="space-y-4" ref={contentRef}>
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4 print:hidden">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Note: The KPI values shown here are based on calculations from the JKR APD Summary Report. To update these values, please go to the JKR APD Summary Report page and click "Calculate KPIs".
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <AppButton
            variant="ghost"
            className="p-2"
            onClick={() => handleMonthChange("prev")}
            icon={<ChevronLeft className="h-4 w-4" />}
          />
          <h2 className="text-2xl font-bold text-center">
            {format(currentDate, "MMMM yyyy")}
          </h2>
          <div className="flex gap-2">
            <AppButton
              variant="ghost"
              className="p-2"
              onClick={() => handleMonthChange("next")}
              icon={<ChevronRight className="h-4 w-4" />}
            />
            <AppButton
              className="print:hidden"
              variant="outline"
              icon={<Printer className="w-4 h-4" />}
              onClick={() => reactToPrintFn()}
            >
              Print
            </AppButton>
          </div>
        </div>

        <div className="flex space-x-4">
          <Card className="w-full flex gap-20 items-center justify-center py-4 shadow-none">
            <div className="flex flex-col gap-4 items-center">
              <p className="font-medium">Failed KPIs</p>
              <p className="text-2xl font-bold">{failedKpis}</p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <p className="font-medium">Achieved KPIs</p>
              <p className="text-2xl font-bold">{achievedKpis}</p>
            </div>
          </Card>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {kpis.map((kpi) => (
            <KpiCard
              key={kpi.code}
              kpiCode={kpi.code}
              description={kpi.description}
              value={kpi.value}
              threshold={kpi.threshold}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
