import { useState, useMemo } from "react";
import {
  Control,
  useController,
  Path,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { cn } from "../../../lib/utils";

interface KPIRow {
  id?: string;
  no: string;
  kpi: string;
  piNo: string;
  performanceIndicator: string;
  target: string;
  actual: string;
  demeritPoint: string;
  demeritPointsImposed: string;
  weightage: string;
  apdValueDeducted: string;
}

interface FormValues {
  kpiRows: KPIRow[];
  verifiedByJkr: Date | null;
  monthlyPaymentValue: string;
  maximumApd: string;
}

interface EditableCellProps {
  index: number;
  fieldName: keyof Omit<KPIRow, "id">;
  control: Control<FormValues>;
  className?: string;
}

export function EditableCell({
  index,
  fieldName,
  control,
  className = "",
}: EditableCellProps) {
  const [isEditing, setIsEditing] = useState(false);

  const {
    field: { onChange, onBlur, name, ref, value },
  } = useController<FormValues>({
    control,
    name: `kpiRows.${index}.${fieldName}` as Path<FormValues>,
  });

  const [actual, target] = useWatch({
    control,
    name: [`kpiRows.${index}.actual`, `kpiRows.${index}.target`],
  });

  const showRed = actual && target && parseFloat(actual) < parseFloat(target);

  const displayValue = useMemo(() => {
    if (!value || isEditing) return value;
    const numValue = parseFloat(value as string);
    if (isNaN(numValue)) return value;
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
    }).format(numValue);
  }, [value, isEditing]);

  return (
    <td
      className={cn(
        "border p-2 text-sm text-right transition-colors",
        isEditing ? "border-blue-400 border-2" : "border-gray-300",
        !isEditing && "print:bg-transparent bg-primary-100 hover:bg-blue-50/50",
        showRed && "text-red-600 font-bold",
        className
      )}
    >
      <input
        type="text"
        value={displayValue as string}
        onChange={(e) => {
          if (isEditing) {
            onChange(e);
          }
        }}
        onFocus={() => {
          setIsEditing(true);
          onChange(value);
        }}
        onBlur={(e) => {
          setIsEditing(false);
          onBlur();
          const numValue = parseFloat(e.target.value);
          if (!isNaN(numValue)) {
            onChange(numValue.toString());
          }
        }}
        name={name}
        ref={ref}
        className={cn(
          "w-full text-right focus:outline-none cursor-text",
          isEditing ? "bg-blue-50/50" : "bg-transparent print:bg-transparent",
          showRed && "text-red-600 font-bold"
        )}
      />
    </td>
  );
}
