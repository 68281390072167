import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSearchParams } from "react-router-dom";
import { Pencil } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { trpc } from "@/lib/trpc";
import AppButton from "@/components/AppButton";
import { useAppSelector } from "@/redux/store";
import useAppStorage from "@/hooks/useAppStorage";
import { formSchema, FormType, WorkOrder } from "./types";
import { VerificationDialog } from "./VerificationDialog";
import { VerificationDisplay } from "./VerificationDisplay";
import { Skeleton } from "../../../../../../ui/skeleton";

export default function VerificationSection() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParam] = useSearchParams();
  const workOrderId = searchParam.get("workOrderId");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const utils = trpc.useUtils();
  const qc = useQueryClient();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      workOrderId: Number(workOrderId),
    },
  });

  const { data: workOrder, isLoading, } = trpc.workOrders.getOneWorkOrder.useQuery(
    { workOrderId: Number(workOrderId) ?? 0 },
    {
      enabled: !!workOrderId,
    }
  );

  useEffect(() => {
    if (workOrder && isDialogOpen) {
      form.setValue("rating", Number(workOrder.rating));
      form.setValue("closingRemark", workOrder.closingRemark ?? "");
      form.setValue("signedBy", workOrder.closingSignedBy ?? "");
      form.setValue(
        "signatory",
        workOrder.closingSignatory
          ? { uploadedSignatory: workOrder.closingSignatory }
          : null
      );
    }
  }, [workOrder, isDialogOpen]);

  const { mutateAsync: updateRatingReview } =
    trpc.workOrders.updateRatingReview.useMutation();

  const { useUploadAttachmentMutation } = useAppStorage();

  const { mutate: deleteVerificationSignatory } =
    trpc.workOrders.deleteVerificationSignatory.useMutation({
      onSuccess: () => {
        utils.workOrders.getOneWorkOrder.invalidate();
        qc.invalidateQueries({
          predicate: (q) =>
            (q.queryKey[0] as string).includes("defect") ||
            (q.queryKey[0] as string).includes("workOrder"),
        });
        form.reset();
      },
    });

  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      setIsDialogOpen(false);
      utils.workOrders.getOneWorkOrder.invalidate();
      qc.invalidateQueries({
        predicate: (q) =>
          (q.queryKey[0] as string).includes("defect") ||
          (q.queryKey[0] as string).includes("workOrder"),
      });
      form.reset();
    },
  });

  const onSubmit = async (data: FormType) => {
    await mutate({
      files: data.signatory?.currentSignatory
        ? [data.signatory.currentSignatory]
        : [],
      mutateAsync: async (atts) => {
        const { signatory, ...updateData } = data;
        await updateRatingReview({
          ...updateData,
          ...(atts && atts.length > 0
            ? {
                signatory: {
                  ...atts[0],
                  projectId: Number(activeProj?.id),
                },
              }
            : {}),
        });
      },
    });
  };

  if (isLoading) return <Skeleton className="w-full h-20" />;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <p className="text-xl font-bold">Verification</p>
        <AppButton
          variant="outline"
          onClick={() => setIsDialogOpen(true)}
          icon={<Pencil className="h-4 w-4" />}
          label="Update Verification"
        />
      </div>

      <VerificationDisplay workOrder={workOrder as WorkOrder} />

      <VerificationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        form={form}
        onSubmit={onSubmit}
        workOrderId={workOrderId}
        isPending={isPending}
        onDeleteSignatory={(workOrderId) =>
          deleteVerificationSignatory({ workOrderId })
        }
      />
    </div>
  );
}
