import { trpc } from "../../../../lib/trpc";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2, Users2, ShieldCheck, UserCog } from "lucide-react";

export default function CCUsersOverview() {
  const projects = trpc.analytics.getProjectsUnderRCI.useQuery();
  const staffBreakdown = trpc.analytics.getStaffBreakdownUnderRCI.useQuery(
    {
      projectIds: projects.data?.map((p) => p.id) ?? [],
    },
    {
      enabled: !!projects.data?.length,
    }
  );

  if (projects.isLoading || staffBreakdown.isLoading) {
    return (
      <div className="w-full h-[400px] flex items-center justify-center">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
      </div>
    );
  }

  if (!projects.data || !staffBreakdown.data) {
    return (
      <div className="w-full h-[400px] flex items-center justify-center">
        No staff data available
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
      {staffBreakdown.data.chartData.map((project) => (
        <Card key={project.name} className="flex flex-col">
          <CardHeader className="pb-2">
            <CardTitle className="text-lg font-semibold">
              {project.name}
            </CardTitle>
            <CardDescription>User Accounts Created</CardDescription>
          </CardHeader>
          <CardContent className="flex-1">
            {/* Total Users */}
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-100 rounded-lg">
                <Users2 className="h-5 w-5 text-blue-600" />
              </div>
              <div>
                <p className="text-sm text-muted-foreground">Total Users</p>
                <p className="text-2xl font-bold">{project.data.total}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
