import { useFormContext, useWatch } from "react-hook-form";
import { useMemo } from "react";
import { cn } from "../../../lib/utils";
import Decimal from "decimal.js";

interface FormValues {
  kpiRows: {
    weightage: string;
    [key: string]: any;
  }[];
}

export function WeightageTotal() {
  const { control } = useFormContext<FormValues>();

  // Watch all kpiRows for changes in weightage
  const kpiRows = useWatch({
    control,
    name: "kpiRows",
  });

  const totalWeightage = useMemo(() => {
    try {
      return kpiRows.reduce((sum, row) => {
        const weightage = new Decimal(row.weightage || '0');
        return sum.plus(weightage);
      }, new Decimal(0));
    } catch (error) {
      return new Decimal(0);
    }
  }, [kpiRows]);

  return (
    <td
      className={cn(
        "border p-2 text-sm text-right",
        !totalWeightage.equals(100) && "text-red-600 font-bold"
      )}
    >
      {totalWeightage.toFixed(2)}
    </td>
  );
}
