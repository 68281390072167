import { ColumnDef, Row } from "@tanstack/react-table";
import { TnbReadingWithAttachment } from "../../../../redux/slices/OpenApi/cerevApi";
import useDate from "../../../../hooks/useDate";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_UPDATE_STATE,
  TNB_BILL_DETAIL_DRAWER,
  TNB_BILL_FORM_DRAWER,
} from "../../../../components/drawers/AppDrawer";

const MonthYearTnbCell = ({ row }: { row: Row<TnbReadingWithAttachment> }) => {
  const { formatDateToLocal } = useDate();
  const [searchParam, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("tnbReadingId", row.getValue("id"));
          p.set("drawerType", TNB_BILL_DETAIL_DRAWER);
          return p;
        });
      }}
    >
      {formatDateToLocal({
        dateStr: row.getValue("billMonth"),
        formatting: "MMM yyyy",
      })}
    </div>
  );
};

const formatNumber = (value: number, withDecimals = false) => {
  const parts = value.toString().split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1] || '';

  // Add commas to the number part
  const formattedNumber = numberPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Add decimal part if needed
  if (withDecimals) {
    const paddedDecimal = decimalPart.padEnd(2, '0').slice(0, 2);
    return `${formattedNumber}.${paddedDecimal}`;
  }

  return formattedNumber;
};

export const tnbBillColumns: ColumnDef<TnbReadingWithAttachment>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "billMonth",
    header: "Month Year",
    cell: ({ row }) => <MonthYearTnbCell row={row} />,
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "kiloWattMd",
    header: "Maximum Demand (kW)",
    cell: ({ row }) => formatNumber(row.getValue("kiloWattMd")) + " kW",
  },
  {
    accessorKey: "kiloWattHr",
    header: "Energy Usage (kWh)",
    cell: ({ row }) => formatNumber(row.getValue("kiloWattHr")) + " kWh",
  },
  {
    accessorKey: "totalBillCost",
    header: "Total Charges (RM)",
    cell: ({ row }) => "RM " + formatNumber(row.getValue("totalBillCost"), true),
  },
];
