import { ColumnDef, Row } from "@tanstack/react-table";
import {
  GetVendorsResponseDto,
  Vendor,
} from "../../../../redux/slices/OpenApi/cerevApi";
import { Rating, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  VENDOR_DETAIL_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import { Badge } from "../../../../components/ui/badge";
import { RouterOutputs } from "@cerev-cmms/trpc/src/lib/trpc/root";

type VendorRow = RouterOutputs["vendor"]["getVendors"]["data"][number]

const VendorNameNav = ({ vendor }: { vendor: Row<VendorRow> }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", VENDOR_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("vendorId", vendor.getValue("id"));
          return p;
        });
      }}
    >
      {vendor.getValue("name") ?? "-"}
    </div>
  );
};

export const vendorColumns: ColumnDef<VendorRow>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <VendorNameNav vendor={row} />,
  },
  {
    id: "workscopes",
    accessorFn: (vn) => vn.workscope.map((ws) => ws.name ?? ""),
    header: "Workscopes",
    cell: ({ row }) => {
      return (
        <div className="flex flex-wrap gap-1">
          {(row.getValue("workscopes") as string[] | undefined)?.map(
            (ws: string, idx) => (
              <Badge key={idx} className="bg-primary-900">
                {ws ?? "-"}
              </Badge>
            )
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "avgScore",
    header: "Rating",
    cell: ({ row }) => {
      if (Number(row.getValue("avgScore")) > 0) {
        return (
          <Rating
            value={parseFloat(row.getValue("avgScore") ?? "0")}
            size="large"
            readOnly
          />
        );
      } else {
        return (
          <p className="font-sans text-neutral-500 text-sm">
            Not enough work done
          </p>
        );
      }
    },
  },
];
