import React from "react";
import { trpc } from "../../../../lib/trpc";
import { useSearchParams } from "react-router-dom";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Project } from "@cerev-cmms/prisma";

export function CCWorkOrderTrend({ project }: { project: Project }) {
  const [searchParams] = useSearchParams();
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;

  const { data, isLoading } = trpc.analytics.getWorkOrderTrend.useQuery(
    {
      projectId: project.id,
      year,
      month,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No work order data available for the selected period
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{project.name}</CardTitle>
        <CardDescription>
          Work orders created per month in {data.year}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[300px] w-full"
        >
          <AreaChart
            data={data.chartData}
            accessibilityLayer
            margin={{ left: -12, right: 40, top: 20, bottom: 40 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={true}
              tick={{ fill: 'hsl(var(--foreground))', fontSize: 12 }}
              tickMargin={8}
              label={{ 
                value: 'Month', 
                position: 'bottom',
                offset: 20,
                fill: 'hsl(var(--foreground))',
                fontSize: 14
              }}
            />
            <YAxis 
              tickLine={false}
              axisLine={true}
              tick={{ fill: 'hsl(var(--foreground))', fontSize: 12 }}
              tickMargin={8}
            />
            <ChartTooltip 
              cursor={false} 
              content={
                <ChartTooltipContent 
                  style={{
                    backgroundColor: 'hsl(var(--background))',
                    border: '1px solid hsl(var(--border))',
                    borderRadius: '6px',
                    padding: '8px 12px',
                  }}
                />
              } 
            />
            <Area
              type="monotone"
              dataKey="count"
              name={data.chartConfig.count.label}
              stroke={`hsl(var(--chart-1))`}
              fill={`hsl(var(--chart-1))`}
              fillOpacity={0.3}
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
