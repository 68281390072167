import { Controller, useFormContext } from "react-hook-form";
import { trpc } from "../../../../lib/trpc";
import AppDocumentUploadTRPC from "../../../AppDocumentUploadTRPC";
import type { RouterOutputs } from "@cerev-cmms/trpc";
import { useSearchParams } from "react-router-dom";

type Attachment = RouterOutputs["vendor"]["getOneProduct"]["attachments"][number];

export default function ProductAttachmentField() {
  const methods = useFormContext();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");
  const utils = trpc.useUtils();

  const { data: product } = trpc.vendor.getOneProduct.useQuery(productId ?? "", {
    enabled: !!productId,
  });

  const { mutate: deleteAttachment } = trpc.vendor.deleteProductAttachment.useMutation({
    onSuccess: () => {
      utils.vendor.getOneProduct.invalidate();
    },
  });

  return (
    <Controller
      control={methods.control}
      name="attachments"
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <AppDocumentUploadTRPC
          uploadedDocs={product?.attachments ?? []}
          onDeleteUploadedDoc={async (att: Attachment) => {
            if (!productId) return;
            deleteAttachment({
              productId,
              attachmentId: att.id.toString(),
            });
          }}
          label="Documents"
          onChange={onChange}
          onDelete={(file) => {
            if (!value) return;
            const newFiles = value.filter((v: File) => v !== file);
            onChange(newFiles);
          }}
          files={value ?? []}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
} 