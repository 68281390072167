import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
} from "@/components/ui/chart";
import { Loader2 } from "lucide-react";
import {
  Bar,
  BarChart,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";
import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

const formatCurrency = (value: number) => {
  const absValue = Math.abs(value);
  const formatted = absValue.toLocaleString('en-MY', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0, 
  });
  return `RM ${formatted}`;
};

export function RevenueByUserByMonth() {
  const [activeUser, setActiveUser] = useState<string | null>(null);
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } =
    trpc.analytics.getWorkOrderRevenueBarTrendByUser.useQuery(
      {
        projectId: project?.id ?? 0,
        month,
        year,
      },
      {
        enabled: !!project,
      }
    );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No revenue data available for the selected period
          </div>
        </CardContent>
      </Card>
    );
  }

  // Get all user keys and sort them by total value
  const userKeys = Object.entries(data.chartConfig)
    .sort((a, b) => b[1].totalValue - a[1].totalValue)
    .map(([key]) => key);

  const getBarOpacity = (userKey: string) => {
    if (!activeUser) return 1;
    return activeUser === userKey ? 1 : 0.3;
  };

  // Calculate total for each period
  const getTotalValue = (data: any) => {
    return userKeys.reduce((sum, key) => sum + (data[key] || 0), 0);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Revenue by User</CardTitle>
        <CardDescription>
          {month && year ? "Daily" : "Monthly"} breakdown of work order revenue
          by user
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[350px] w-full"
        >
          <BarChart 
            data={data.chartData} 
            accessibilityLayer
            margin={{ top: 40, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value: number) => formatCurrency(value)}
              width={100}
            />
            <ChartTooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length > 0) {
                  // Filter to only show the active user's data if there's a hover
                  const displayPayload = activeUser
                    ? payload.filter(p => p.dataKey === activeUser)
                    : payload;

                  return (
                    <div className="rounded-lg border bg-white p-2 shadow-sm">
                      <div className="text-sm font-medium">{label}</div>
                      {displayPayload.map((entry: any) => (
                        <div
                          key={entry.dataKey}
                          className="flex items-center gap-2"
                        >
                          <div
                            className="h-2 w-2 rounded-full"
                            style={{ backgroundColor: entry.fill }}
                          />
                          <span className="text-sm text-muted-foreground">
                            {data.chartConfig[entry.dataKey].label}:
                          </span>
                          <span className="text-sm font-medium">
                            {formatCurrency(entry.value)}
                          </span>
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              }}
            />
            {userKeys.map((userKey) => (
              <Bar
                key={userKey}
                dataKey={userKey}
                name={data.chartConfig[userKey].label}
                fill={data.chartConfig[userKey].color}
                stackId="users"
                opacity={getBarOpacity(userKey)}
                onMouseEnter={() => setActiveUser(userKey)}
                onMouseLeave={() => setActiveUser(null)}
              >
                {userKey === userKeys[userKeys.length - 1] && (
                  <LabelList
                    dataKey={(entry: any) => getTotalValue(entry)}
                    position="top"
                    formatter={(value: number) => value !== 0 ? formatCurrency(value) : null}
                    style={{ fill: '#0f172a', fontWeight: "bold" }}
                    offset={8}
                  />
                )}
              </Bar>
            ))}
          </BarChart>
        </ChartContainer>

        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
          {userKeys.map((userKey) => (
            <div
              key={userKey}
              className="flex items-center space-x-2 rounded-lg bg-card p-2 hover:bg-accent/10 cursor-pointer"
              onMouseEnter={() => setActiveUser(userKey)}
              onMouseLeave={() => setActiveUser(null)}
              style={{
                opacity: getBarOpacity(userKey),
              }}
            >
              <div className="flex items-center space-x-2">
                <div
                  className="h-3 w-3 rounded-sm"
                  style={{
                    backgroundColor: data.chartConfig[userKey].color,
                  }}
                />
                <div className="flex flex-col">
                  <span className="text-xs font-medium text-foreground">
                    {data.chartConfig[userKey].label}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
} 