import { useFormContext, useWatch } from "react-hook-form";
import { useMemo } from "react";
import { cn } from "../../../lib/utils";

interface FormValues {
  kpiRows: {
    target: string;
    actual: string;
    demeritPoint: string;
    [key: string]: any;
  }[];
}

interface DemeritPointsImposedCellProps {
  index: number;
}

export function DemeritPointsImposedCell({ index }: DemeritPointsImposedCellProps) {
  const { control } = useFormContext<FormValues>();

  // Watch the required fields for computation
  const [actual, target, demeritPoint] = useWatch({
    control,
    name: [
      `kpiRows.${index}.actual`,
      `kpiRows.${index}.target`,
      `kpiRows.${index}.demeritPoint`,
    ],
  });

  // Compute demeritPointsImposed value
  const computedValue = useMemo(() => {
    try {
      const actualValue = parseFloat(actual || '0');
      const targetValue = parseFloat(target || '0');
      const demeritPointValue = parseFloat(demeritPoint || '0');

      return actualValue < targetValue ? demeritPointValue : 0;
    } catch (error) {
      return 0;
    }
  }, [actual, target, demeritPoint]);

  const showRed = actual && target && parseFloat(actual) < parseFloat(target);

  return (
    <td className={cn(
      "border p-2 text-sm text-right",
      showRed && "text-red-600 font-bold"
    )}>
      {computedValue.toString()}
    </td>
  );
} 