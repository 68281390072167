import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { trpc } from "../../../lib/trpc";
import AppTextField from "../../AppTextField";
import { DRAWER_CREATE_STATE } from "../AppDrawer";
import ProductAttachmentField from "./components/ProductAttachmentField";
import type { RouterOutputs } from "@cerev-cmms/trpc";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import useAppStorage from "../../../hooks/useAppStorage";
import AppButton from "../../AppButton";

type Product = RouterOutputs["vendor"]["getOneProduct"];

const productFormSchema = z.object({
  name: z.string().min(1, "Name is required"),
  description: z.string().optional(),
  attachments: z.array(z.instanceof(File)).optional(),
});

type ProductFormType = z.infer<typeof productFormSchema>;

export default function ProductFormDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const productId = searchParams.get("productId");
  const vendorId = searchParams.get("vendorId");
  const drawerState = searchParams.get("drawerState");
  const utils = trpc.useUtils();
  const nav = useNavigate();

  const methods = useForm<ProductFormType>({
    resolver: zodResolver(productFormSchema),
    defaultValues: {
      name: "",
      description: "",
      attachments: [],
    },
  });

  const { data: product, isLoading } = trpc.vendor.getOneProduct.useQuery(
    productId ?? "",
    {
      enabled: !!productId && drawerState !== DRAWER_CREATE_STATE,
    }
  );

  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending: isUploading } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      utils.vendor.getProducts.invalidate();
      utils.vendor.getOneProduct.invalidate();
      nav(-1);
    },
  });

  const { mutateAsync: createProduct, isPending: isCreating } =
    trpc.vendor.createProduct.useMutation();

  const { mutateAsync: updateProduct, isPending: isUpdating } =
    trpc.vendor.updateProduct.useMutation();

  useEffect(() => {
    if (product && !methods.formState.isDirty) {
      methods.reset({
        name: product.name,
        description: product.description ?? "",
      });
    }
  }, [product, methods]);

  const onSubmit = async (data: ProductFormType) => {
    if (!vendorId) return;

    mutate({
      files: data.attachments ?? [],
      mutateAsync: async (atts) => {
        try {
          if (drawerState === DRAWER_CREATE_STATE) {
            await createProduct({
              name: data.name,
              description: data.description,
              vendorId: parseInt(vendorId),
              attachments: atts.map((a) => ({
                name: a.name ?? "",
                fileSizeMb: a.fileSizeMb ?? "",
                gsPath: a.gsPath ?? "",
                url: a.url ?? "",
                underProject: { id: parseInt(vendorId) },
                underProjectId: parseInt(vendorId),
              })),
            });
          } else if (product) {
            await updateProduct({
              id: product.id,
              name: data.name,
              description: data.description,
              vendorId: parseInt(vendorId),
              attachments: atts.map((a) => ({
                name: a.name ?? "",
                fileSizeMb: a.fileSizeMb ?? "",
                gsPath: a.gsPath ?? "",
                url: a.url ?? "",
                underProject: { id: parseInt(vendorId) },
                underProjectId: parseInt(vendorId),
              })),
            });
          }
        } catch (error) {
          console.error("Error submitting product:", error);
        }
      },
    });
  };

  if (isLoading) {
    return <DrawerFormSkeleton />;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold">
        {drawerState === DRAWER_CREATE_STATE
          ? "Create Product"
          : "Update Product"}
      </h2>
      <FormProvider {...methods}>
        <div className="space-y-4">
          <AppTextField
            label="Name *"
            name="name"
            placeholder="Enter product name"
          />
          <AppTextField
            label="Description"
            name="description"
            placeholder="Enter product description"
          />
          <ProductAttachmentField />
          <AppButton
            isLoading={isCreating || isUpdating || isUploading}
            onClick={methods.handleSubmit(onSubmit)}
            label={
              isCreating || isUpdating || isUploading
                ? "Saving..."
                : drawerState === DRAWER_CREATE_STATE
                ? "Create"
                : "Update"
            }
          />
        </div>
      </FormProvider>
    </div>
  );
}
