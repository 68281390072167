import { DateFilter } from "../WorkRequestAnalytics/DateFilter";
import { CustomerPnLApportionment } from "./CustomerPnLApportionment";
import { PnLByMonth } from "./PnLByMonth";
import { PnLByUserByMonth } from "./PnLByUserByMonth";

export function PnLAnalytic() {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between my-4">
        <DateFilter />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <PnLByMonth />
        </div>
        <div className="col-span-2">
          <PnLByUserByMonth />
        </div>
        <div className="col-span-2">
          <CustomerPnLApportionment />
        </div>
      </div>
    </div>
  );
}
