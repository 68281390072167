import { TabsList, TabsTrigger, TabsContent } from "../../../components/ui/tabs";
import LegalComplianceAnalytics from "./LegalComplianceAnalytics/LegalComplianceAnalytics";
import { PMAnalytic } from "./PMAnalytics/PMAnalytic";
import { WorkOrderAnalytics } from "./WorkOrderAnalytics/WorkOrderAnalytics";
import { WorkRequestAnalytics } from "./WorkRequestAnalytics/WorkRequestAnalytics";

interface DashboardProps {
  currentTabIdx: string;
}

export default function StandardDashboard({ currentTabIdx }: DashboardProps) {
  return (
    <>
      <TabsList>
        <TabsTrigger value="0">
          <p>Work Request</p>
        </TabsTrigger>
        <TabsTrigger value="1">
          <p>Work Order</p>
        </TabsTrigger>
        <TabsTrigger value="2">
          <p>Preventive Maintenance</p>
        </TabsTrigger>
        <TabsTrigger value="4">
          <p>Legal Compliance</p>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="0">
        <WorkRequestAnalytics />
      </TabsContent>
      <TabsContent value="1">
        <WorkOrderAnalytics />
      </TabsContent>
      <TabsContent value="2">
        <PMAnalytic />
      </TabsContent>
      <TabsContent value="4">
        <LegalComplianceAnalytics />
      </TabsContent>
    </>
  );
}
