import { Star, AlignLeft, Pen } from "lucide-react";
import AppItemInfo from "@/components/AppItemInfo";
import AppImage from "@/components/AppImage";
import { AppRatings } from "@/components/rating/AppRatings";
import { WorkOrder } from "./types";

interface VerificationDisplayProps {
  workOrder: WorkOrder | undefined;
}

export function VerificationDisplay({ workOrder }: VerificationDisplayProps) {
  return (
    <div className="space-y-6">
      <AppItemInfo
        label="Rating"
        icon={<Star className="text-primary-900" />}
        content={
          workOrder?.rating ? (
            <div>
              <AppRatings
                rating={workOrder?.rating ? Number(workOrder?.rating) : 0}
              />
            </div>
          ) : (
            <>-</>
          )
        }
      />
      <AppItemInfo
        label="Closing Remark"
        icon={<AlignLeft />}
        content={<p>{workOrder?.closingRemark ?? "-"}</p>}
      />
      <AppItemInfo
        label="Closing Signatory"
        icon={<Pen />}
        content={
          workOrder?.closingSignatory ? (
            <AppImage
              att={workOrder.closingSignatory}
              className="max-w-xs rounded-md"
            />
          ) : (
            <p>-</p>
          )
        }
      />
      <AppItemInfo
        label="Signed By"
        icon={<Pen />}
        content={<p>{workOrder?.closingSignedBy ?? "-"}</p>}
      />
    </div>
  );
} 