import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import { ContractStatusCard } from "./ContractStatusCard";

export function FmStatutoryMetrics() {
  const activeProject = useAppSelector((state) => state.root.activeProject);

  const { data: contractStats } =
    trpc.analytics.getFmStatutoryContracts.useQuery(
      {
        projectId: Number(activeProject?.id),
      },
      {
        enabled: !!activeProject?.id,
      }
    );

  if (!contractStats) return null;

  const activeFmContracts =
    contractStats.fmContracts.total - contractStats.fmContracts.expired;
  const activeStatutoryContracts =
    contractStats.statutoryContracts.total -
    contractStats.statutoryContracts.expired;

  return (
    <div className="space-y-4">
      <div className="grid gap-4 grid-cols-2">
        <ContractStatusCard
          title="Expired FM Contracts"
          value={contractStats.fmContracts.expired}
          total={contractStats.fmContracts.total}
          className="bg-red-50"
        />
        <ContractStatusCard
          title="Expired Statutory"
          value={contractStats.statutoryContracts.expired}
          total={contractStats.statutoryContracts.total}
          className="bg-red-50"
        />
      </div>
      <div className="grid gap-4 grid-cols-2">
        <ContractStatusCard
          title="Active FM Contracts"
          value={activeFmContracts}
          total={contractStats.fmContracts.total}
          className="bg-green-50"
        />
        <ContractStatusCard
          title="Active Statutory"
          value={activeStatutoryContracts}
          total={contractStats.statutoryContracts.total}
          className="bg-green-50"
        />
      </div>
    </div>
  );
}
