import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartContainer,
  ChartLegend,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Loader2 } from "lucide-react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import { useSearchParams } from "react-router-dom";

const formatCurrency = (value: number) => {
  const absValue = Math.abs(value);
  const formatted = absValue.toLocaleString('en-MY', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0, 
  });
  return value < 0 ? `-RM ${formatted}` : `RM ${formatted}`;
};

export function PnLByMonth() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } =
    trpc.analytics.getWorkOrderProfitBarTrend.useQuery(
      {
        projectId: project?.id ?? 0,
        month,
        year,
      },
      {
        enabled: !!project,
      }
    );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No profit/loss data available for the selected period
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Profit & Loss Trend</CardTitle>
        <CardDescription>
          {month && year ? 'Daily' : 'Monthly'} breakdown of work order profit and loss
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[350px] w-full"
        >
          <BarChart data={data.chartData} accessibilityLayer margin={{ top: 40, right: 30, left: 20, bottom: 5 }}>
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value: number) => formatCurrency(value)}
              width={100}
            />
            <ChartTooltip 
              content={
                <ChartTooltipContent 
                  indicator="dot"
                  formatter={(value) => formatCurrency(Number(value))}
                />
              } 
            />
            <Bar
              dataKey="value"
              name="Profit/Loss"
              radius={[8, 8, 0, 0]}
            >
              <LabelList
                position="top"
                offset={8}
                formatter={(value: number) => value !== 0 ? formatCurrency(value) : null}
                style={{ fill: '#0f172a', fontWeight: "bold" }}
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
