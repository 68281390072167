import { z } from "zod";
import { updateRatingReviewSchema } from "@cerev-cmms/zod-types";

export const formSchema = updateRatingReviewSchema
  .pick({
    workOrderId: true,
    rating: true,
    closingRemark: true,
    signedBy: true,
  })
  .extend({
    signatory: z
      .object({
        uploadedSignatory: z
          .object({
            name: z.string(),
            gsPath: z.string(),
          })
          .optional()
          .nullable(),
        currentSignatory: z.instanceof(File).optional().nullable(),
      })
      .optional()
      .nullable(),
  });

export type FormType = z.infer<typeof formSchema>;

export interface WorkOrder {
  id: number;
  rating: number | null;
  closingRemark: string | null;
  closingSignedBy: string | null;
  closingSignatory: {
    name: string;
    gsPath: string;
  } | null;
} 