import React, { useState } from "react";
import { trpc } from "../../../../lib/trpc";
import { PieChart, Pie, Cell, Label } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
} from "@/components/ui/chart";
import { useAppSelector } from "../../../../redux/store";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import AppButton from "@/components/AppButton";
import { useSearchParams } from "react-router-dom";

const formatCurrency = (value: number) => {
  const absValue = Math.abs(value);
  const formatted = absValue.toLocaleString("en-MY", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return `RM ${formatted}`;
};

export function CustomerRevenueApportionment() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [showAllMetrics, setShowAllMetrics] = useState(false);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } =
    trpc.analytics.getCustomerRevenueApportionment.useQuery(
      {
        projectId: project?.id ?? 0,
        month,
        year,
      },
      {
        enabled: !!project,
      }
    );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  const processChartData = (data: typeof filteredChartData) => {
    const totalValue = data.reduce((sum, item) => sum + item.value, 0);
    let currentSum = 0;
    const topCustomers = [];

    // Get customers until we reach 60% of total value
    for (const item of data) {
      currentSum += item.value;
      topCustomers.push(item);
      if ((currentSum / totalValue) * 100 > 60) break;
    }

    // If we have more customers, group them into "Others"
    if (data.length > topCustomers.length) {
      const others = data.slice(topCustomers.length).reduce(
        (acc, curr) => ({
          name: "Others",
          value: acc.value + curr.value,
          percentage: "0",
        }),
        { name: "Others", value: 0, percentage: "0" }
      );

      others.percentage = ((others.value / totalValue) * 100).toFixed(1);
      return [...topCustomers, others];
    }

    return data;
  };

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No customer revenue data available
          </div>
        </CardContent>
      </Card>
    );
  }

  const filteredChartData = data.chartData.filter((entry) => entry.value !== 0);
  const processedChartData = processChartData(filteredChartData);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Customer Revenue Breakdown</CardTitle>
        <CardDescription>Distribution of revenue by customer</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[300px] w-full"
        >
          <PieChart>
            <Pie
              data={processedChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={120}
              innerRadius={90}
              startAngle={90}
              endAngle={-270}
            >
              {processedChartData.map((entry) => (
                <Cell
                  key={entry.name}
                  fill={
                    entry.name === "Others"
                      ? "#94a3b8" // Slate-400 for Others
                      : data.chartConfig[`location${entry.name.split(' ')[0].toLowerCase()}`]?.color
                  }
                />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-xl font-bold"
                        >
                          {formatCurrency(data.totalRevenue)}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Total Revenue
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <ChartTooltip
              cursor={false}
              content={({ active, payload }) => {
                if (active && payload && payload.length > 0) {
                  const data = payload[0].payload;
                  return (
                    <div className="rounded-lg border bg-white p-2 shadow-sm">
                      <div className="grid grid-cols-2 gap-2">
                        <div className="flex items-center gap-2">
                          <div
                            className="h-2 w-2 rounded-full"
                            style={{
                              backgroundColor: data.fill,
                            }}
                          />
                          <span className="text-sm text-muted-foreground">
                            {data.name}
                          </span>
                        </div>
                        <div className="text-right text-sm font-medium">
                          {formatCurrency(data.value)}
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              }}
            />
          </PieChart>
        </ChartContainer>
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
          {processedChartData.map((item) => (
            <div
              key={item.name}
              className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm transition-colors hover:bg-accent/10"
            >
              <div className="flex items-center space-x-2">
                <div
                  className="h-3 w-3 rounded-sm"
                  style={{
                    backgroundColor: item.name === "Others"
                      ? "#94a3b8"
                      : data.chartConfig[`location${item.name.split(' ')[0].toLowerCase()}`]?.color,
                  }}
                />
                <div className="flex flex-col">
                  <span className="text-xs font-medium text-foreground">
                    {item.name}
                  </span>
                  <span className="text-sm font-semibold">
                    {formatCurrency(item.value)} ({item.percentage}%)
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {filteredChartData.length > processedChartData.length && (
          <div className="mt-4 flex justify-center">
            <AppButton
              variant="outline"
              label="View All Customers"
              onClick={() => setShowAllMetrics(true)}
            />
          </div>
        )}

        <Dialog open={showAllMetrics} onOpenChange={setShowAllMetrics}>
          <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>All Customers Revenue</DialogTitle>
            </DialogHeader>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
              {filteredChartData.map((item) => (
                <div
                  key={item.name}
                  className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm"
                >
                  <div className="flex items-center space-x-2">
                    <div
                      className="h-3 w-3 rounded-sm"
                      style={{
                        backgroundColor: data.chartConfig[`location${item.name.split(' ')[0].toLowerCase()}`]?.color,
                      }}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs font-medium text-foreground">
                        {item.name}
                      </span>
                      <span className="text-sm font-semibold">
                        {formatCurrency(item.value)} ({item.percentage}%)
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
} 