import AppButton from '@/components/AppButton';
import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Trash } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/redux/store';
import { toast } from 'sonner';
import { trpc } from '../../../../../../lib/trpc';

export default function DeleteWorkOrderBtn() {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const workOrderId = searchParams.get('workOrderId');
  const activeProj = useAppSelector(state => state.root.activeProject);
  const utils = trpc.useUtils();

  const [open, setIsOpen] = useState(false);

  const { mutate, isPending } = trpc.workOrders.deleteWorkOrder.useMutation({
    onSuccess: () => {
      qc.invalidateQueries({
        predicate: q => (q.queryKey[0] as string)?.includes("defect") || (q.queryKey[0] as string)?.includes("work-request")
      });
      utils.workOrders.invalidate();
      setIsOpen(false);
      setSearchParams(new URLSearchParams());
      toast.success("Work order deleted successfully");
    },
  });

  const onDeleteWorkOrder = () => {
    if (!workOrderId || !activeProj?.id) return;
    
    mutate({
      workOrderId: workOrderId,
      projectId: activeProj.id.toString()
    });
  };

  return (
    <>
      <AppButton 
        label="Delete Work Order" 
        variant="destructive" 
        icon={<Trash />} 
        onClick={() => setIsOpen(true)}
      />
      <Dialog open={open} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this work order? This action is irreversible.
            </DialogDescription>
            <DialogFooter>
              <AppButton 
                variant="outline" 
                label="Cancel" 
                onClick={() => setIsOpen(false)}
              />
              <AppButton 
                label="Confirm" 
                variant="destructive" 
                onClick={onDeleteWorkOrder} 
                isLoading={isPending}
              />
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
}