import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ChevronDown, Loader2 } from "lucide-react";
import { trpc } from "../../../../lib/trpc";
import { useSearchParams } from "react-router-dom";
import { Project } from "@cerev-cmms/prisma";

export function CCWorkRequestKpiAvgTurnAroundTime({ project }: { project: Project }) {
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month") ? parseInt(searchParams.get("month") ?? "") : undefined;
  const year = searchParams.get("year") ? parseInt(searchParams.get("year") ?? "") : undefined;

  const { data, isLoading, isError } = trpc.analytics.getWorkRequestKpiAvgTurnaroundTime.useQuery(
    {
      projectId: project.id,
      month,
      year,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[200px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (isError || !data || data.totalRequests === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[200px] flex items-center justify-center">
            No work request data available
          </div>
        </CardContent>
      </Card>
    );
  }

  const avgHours = data.averageTurnaroundHours;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{project.name}</CardTitle>
        <CardDescription>
          {project.name} - Average turnaround time: {avgHours.toFixed(1)} hours
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6 mt-6">
        {/* Progress Bar */}
        <div className="px-20">
          <div className="relative w-full h-4 mb-8">
            <div className="absolute inset-0 flex rounded-full overflow-hidden">
              <div className="w-[24%] bg-green-400" />
              <div className="w-[24%] bg-yellow-400" />
              <div className="w-[52%] bg-red-400" />
            </div>

            <div
              className="absolute top-0 bottom-0 w-0.5 bg-primary-foreground"
              style={{ left: `${Math.min((avgHours / 100) * 100, 100)}%` }}
            />

            <div
              className="absolute -top-10 transform -translate-x-1/2 whitespace-nowrap flex flex-col items-center"
              style={{ left: `${Math.min((avgHours / 100) * 100, 100)}%` }}
            >
              <span className="font-bold">{avgHours.toFixed(1)}h</span>
              <ChevronDown className="h-6 w-6 text-primary-foreground" />
            </div>

            <div className="absolute -bottom-6 left-0 right-0 flex justify-between text-xs text-muted-foreground">
              <span>0h</span>
              <span className="absolute left-[24%] transform -translate-x-1/2">24h</span>
              <span className="absolute left-[48%] transform -translate-x-1/2">48h</span>
              <span>100h</span>
            </div>
          </div>
        </div>

        {/* Total Requests */}
        <div className="pt-6 text-center">
          <p className="text-sm text-muted-foreground">Total Closed Requests</p>
          <p className="text-2xl font-bold">{data.totalRequests}</p>
        </div>
      </CardContent>
    </Card>
  );
} 