import { DateFilter } from "../WorkRequestAnalytics/DateFilter";
import { CustomerRevenueApportionment } from "./CustomerRevenueApportionment";
import { RevenueByMonth } from "./RevenueByMonth";
import { RevenueByUserByMonth } from "./RevenueByUserByMonth";

export default function RevenueAnalytic() {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between my-4">
        <DateFilter />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <RevenueByMonth />
        </div>
        <div className="col-span-2">
          <RevenueByUserByMonth />
        </div>
        <div className="col-span-2">
          <CustomerRevenueApportionment />
        </div>
      </div>
    </div>
  );
}
