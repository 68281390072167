import { z } from 'zod';

export const getKwhReadingByMonthSchema = z.object({
  projectId: z.string(),
  year: z.number(),
});

export type GetKwhReadingByMonthInput = z.infer<typeof getKwhReadingByMonthSchema>;

export const getRMByMonthSchema = z.object({
  projectId: z.string(),
  year: z.number(),
});

export type GetRMByMonthInput = z.infer<typeof getRMByMonthSchema>;
