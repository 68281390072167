import { useSearchParams } from "react-router-dom";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { trpc } from "../../../lib/trpc";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import { VendorInfoTab } from "./components/VendorInfoTab";
import { ProductsTab } from "./components/ProductsTab";

export default function VendorDetailDrawer() {
  const [searchParams] = useSearchParams();
  const vendorId = searchParams.get("vendorId");
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "vendorDetailTabIdx",
    defaultValue: "0",
  });

  const { data: vendor, isLoading } = trpc.vendor.getOneVendor.useQuery(
    vendorId ?? "",
    {
      enabled: !!vendorId,
    }
  );

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="space-y-4">
      <p className="font-sans text-2xl font-bold">{vendor?.name ?? "-"}</p>
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        <TabsList>
          <TabsTrigger value="0">
            <p>Info</p>
          </TabsTrigger>
          <TabsTrigger value="1">
            <p>Products</p>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          {vendor && <VendorInfoTab vendor={vendor} />}
        </TabsContent>
        <TabsContent value="1">
          <ProductsTab />
        </TabsContent>
      </Tabs>
    </div>
  );
}
