import { Banknote, CandlestickChart, Receipt } from "lucide-react";
import { Card, CardContent } from "../../../../../ui/card";
import { useSearchParams } from "react-router-dom";
import { useDefectControllerGetOneDefect } from "../../../../../../api/work-orders-defects/work-orders-defects";
import Decimal from "decimal.js";

export default function SummarySection() {
  const [searchParam] = useSearchParams();
  const activeWoId = searchParam.get("workOrderId");
  const { data: workOrder } = useDefectControllerGetOneDefect(
    activeWoId ?? "",
    {
      query: {
        select: (res) => res.data,
      },
    }
  );

  // Calculate total cost (sum of labour, part, and mileage costs)
  const totalCost = new Decimal(workOrder?.labourCosting ?? 0)
    .plus(new Decimal(workOrder?.partCosting ?? 0))
    .plus(new Decimal(workOrder?.milleageCosting ?? 0));

  // Calculate profit/loss
  const profitLoss = new Decimal(workOrder?.costing ?? 0).minus(totalCost);

  const formatCurrency = (value: Decimal | number | string) => {
    return new Intl.NumberFormat("en-MY", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  };

  const getProfitLossStyles = () => {
    if (profitLoss.isPositive()) {
      return {
        card: "bg-green-50 border-green-100 shadow-green-100/50",
        text: "text-green-600",
        icon: "text-green-600"
      };
    }
    return {
      card: "bg-red-50 border-red-100 shadow-red-100/50",
      text: "text-red-600",
      icon: "text-red-600"
    };
  };

  const styles = getProfitLossStyles();

  return (
    <div className="space-y-2">
      <h3 className="text-xl font-semibold">Summary</h3>
      <div className="grid grid-cols-3 gap-4">
        <Card className="transition-all duration-200">
          <CardContent className="py-4 flex gap-6 items-center">
            <Banknote className="text-primary-900 h-10 w-10" />
            <div className="flex flex-col">
              <p className="text-2xl">
                RM {formatCurrency(workOrder?.costing ?? "0.00")}
              </p>
              <p className="text-sm text-slate-400">Revenue</p>
            </div>
          </CardContent>
        </Card>
        <Card className="transition-all duration-200">
          <CardContent className="py-4 flex gap-6 items-center">
            <Receipt className="text-primary-900 h-10 w-10" />
            <div className="flex flex-col">
              <p className="text-2xl">RM {formatCurrency(totalCost)}</p>
              <p className="text-sm text-slate-400">Cost</p>
            </div>
          </CardContent>
        </Card>
        <Card className={`transition-all duration-200 ${styles.card}`}>
          <CardContent className="py-4 flex gap-6 items-center">
            <CandlestickChart className={`h-10 w-10 ${styles.icon}`} />
            <div className="flex flex-col">
              <p className={`text-2xl font-medium ${styles.text}`}>
                RM {formatCurrency(profitLoss)}
              </p>
              <p className="text-sm text-slate-400">Estimated P&L</p>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
