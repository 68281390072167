import { useFormContext, useWatch } from "react-hook-form";
import { useMemo } from "react";
import { cn } from "../../../lib/utils";
import Decimal from "decimal.js";

interface KPIRow {
  weightage: string;
  actual: string;
  target: string;
  [key: string]: any;
}

interface FormValues {
  monthlyPaymentValue: string;
  maximumApd: string;
  kpiRows: KPIRow[];
}

export function TotalApdValue() {
  const { control } = useFormContext<FormValues>();

  // Watch all necessary fields with proper typing
  const kpiRows = useWatch({
    control,
    name: "kpiRows",
  });

  const monthlyPaymentValue = useWatch({
    control,
    name: "monthlyPaymentValue",
  });

  const maximumApd = useWatch({
    control,
    name: "maximumApd",
  });

  const totalApd = useMemo(() => {
    try {
      return kpiRows.reduce((sum: Decimal, row: KPIRow) => {
        const mpv = new Decimal(monthlyPaymentValue || '0');
        const maxApd = new Decimal(maximumApd || '0');
        const weightage = new Decimal(row.weightage || '0');
        
        const apdValue = mpv
          .times(weightage)
          .times(maxApd)
          .dividedBy(10000);
        
        return sum.plus(apdValue);
      }, new Decimal(0));
    } catch (error) {
      return new Decimal(0);
    }
  }, [kpiRows, monthlyPaymentValue, maximumApd]);

  return (
    <td className="border p-2 text-sm text-right font-bold">
      {new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(totalApd.toNumber())}
    </td>
  );
} 