import { useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import { CerevLogo } from "../../icons/CerevLogo";
import { Download, Loader2, XCircle } from "lucide-react";
import AppButton from "../../components/AppButton";
import { trpc } from "../../lib/trpc";
import * as XLSX from "xlsx";
import { useCallback } from "react";

export default function SummaryInventoryDownloadScreen() {
  const { projectId } = useParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: inventorySummary, isLoading } =
    trpc.inventory.getInventorySummary.useQuery(
      {
        projectId: Number(projectId),
      },
      {
        enabled: !!projectId,
      }
    );

  const handleExport = useCallback(() => {
    if (!inventorySummary) return;

    // Prepare data for export
    const exportData = inventorySummary.map((stock) => ({
      "Project Name": stock.project?.name || "",
      "Stock Name": stock.name,
      "Stock Description": stock.description || "",
      "Stock Balance": stock.balance,
    }));

    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Inventory Summary");

    // Generate and download file
    XLSX.writeFile(wb, "inventory-summary.xlsx");
  }, [inventorySummary]);

  if (isLoading)
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
          <p className="text-slate-900 font-bold">Fetching your data...</p>
          <p className="text-slate-500 text-sm">
            This process may take up to 5 - 10 mins depending on size of data...
          </p>
        </div>
      </div>
    );

  if (!inventorySummary) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <XCircle className="text-red-500  w-12 h-12" />
          <p className="text-red-500 font-bold">Something went wrong</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div className="h-20 w-20 mb-2">
        <CerevLogo />
      </div>
      <p className="font-bold text-2xl mb-6">Cerev</p>
      <div className="flex flex-col items-center justify-center gap-4">
        <AppButton
          label="Download"
          variant="outline"
          icon={<Download />}
          onClick={handleExport}
        />
        <p className="text-slate-900 font-bold">Your data is ready to download</p>
      </div>
    </div>
  );
}
