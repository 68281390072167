import { useSearchParams } from "react-router-dom";
import useDate from "../../../hooks/useDate";
import { trpc } from "../../../lib/trpc";
import AppItemInfo from "../../AppItemInfo";
import AppMultiImage from "../../AppMultiImage";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import {
  ATTACHMENT_TYPE,
  BOOL_TYPE,
  NUMBER_TYPE,
  VARCHAR_TYPE,
} from "./PmChecklistFormDrawer";
import {
  Calendar,
  CalendarClock,
  FileImage,
  Hash,
  MapPin,
  Pencil,
  Tag,
  TextCursorInput,
  ToggleLeft,
} from "lucide-react";
import AppButton from "../../AppButton";
import { DRAWER_UPDATE_STATE, PM_CHECKLIST_FORM_DRAWER } from "../AppDrawer";
import PmChecklistEquipmentInfo from "../../pm/PmChecklistEquipmentInfo";

export default function PmChecklistDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pmChecklistId = searchParams.get("pmChecklistId");
  const { formatDateToLocal, diffInDays } = useDate();

  const { data: pmChecklist, isLoading } = trpc.pm.getOnePmChecklist.useQuery(
    {
      pmChecklistId: Number(pmChecklistId),
    },
    {
      enabled: !!pmChecklistId,
    }
  );

  const pmFormats = pmChecklist?.pmTemplate.pmFormat.sort((a, b) => a.order - b.order) ?? [];

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4 items-center">
        <h1 className="font-sans text-2xl font-bold">PM Checklist</h1>
        <AppButton
          label="Update"
          icon={<Pencil />}
          variant="outline"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", PM_CHECKLIST_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("pmChecklistId", pmChecklistId ?? "");
              return p;
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-gray-400">Status</p>
        <div className="flex">
          <div
            className="rounded-md py-2 px-10 mb-2 text-white font-bold"
            style={{ backgroundColor: `#${pmChecklist?.pmStatus?.colorHex}` }}
          >
            {pmChecklist?.pmStatus?.name}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <PmChecklistEquipmentInfo
            isMultipleEquipments={pmChecklist?.pmTemplate.pmTemplateType === 'MULTIPLE_EQUIPMENTS'}
            asset={pmChecklist?.asset}
            multipleAssets={pmChecklist?.multipleAssets}
          />
          <AppItemInfo
            label="Created"
            icon={<Calendar className="text-primary-900" />}
            content={
              <p>
                {formatDateToLocal({
                  dateStr: pmChecklist?.createdOn.toISOString() ?? "",
                  formatting: "d MMM yyyy",
                })}
              </p>
            }
          />
          <AppItemInfo
            label="Aging Days"
            icon={<CalendarClock className="text-primary-900" />}
            content={
              <p>
                {pmChecklist?.completedOn
                  ? diffInDays({
                      startDateISO: pmChecklist?.createdOn.toISOString(),
                      endDateISO: pmChecklist?.completedOn.toISOString(),
                    })
                  : "-"}
              </p>
            }
          />
        </div>
        <p className="font-sans text-xl font-bold my-4">Checklist</p>
        <div className="flex flex-col gap-4">
          {pmFormats.map((pmF) => {
            switch (pmF.pmFormatType.name) {
              case VARCHAR_TYPE:
                return (
                  <AppItemInfo
                    key={pmF.id}
                    label={pmF.name ?? "-"}
                    icon={<TextCursorInput className="text-primary-900" />}
                    content={
                      <p className="font-sans">
                        {pmChecklist?.pmChecklistText?.find(
                          (v) => v.pmFormatId === pmF.id
                        )?.value ?? "-"}
                      </p>
                    }
                  />
                );
              case NUMBER_TYPE:
                return (
                  <AppItemInfo
                    key={pmF.id}
                    label={pmF.name ?? "-"}
                    icon={<Hash className="text-primary-900" />}
                    content={
                      <p className="font-sans">
                        {Number(pmChecklist?.pmChecklistNumber?.find(
                          (v) => v.pmFormatId === pmF.id
                        )?.value) ?? "-"}
                      </p>
                    }
                  />
                );
              case BOOL_TYPE:
                return (
                  <AppItemInfo
                    key={pmF.id}
                    label={pmF.name ?? "-"}
                    icon={<ToggleLeft className="text-primary-900" />}
                    content={
                      <p className="font-sans">
                        {pmChecklist?.pmChecklistBool?.find(
                          (v) => v.pmFormatId === pmF.id
                        )?.value
                          ? "YES"
                          : "NO"}
                      </p>
                    }
                  />
                );
              case ATTACHMENT_TYPE:
                return (
                  <AppItemInfo
                    key={pmF.id}
                    label={pmF.name ?? "-"}
                    icon={<FileImage className="text-primary-900" />}
                    content={
                      <AppMultiImage
                        attachments={
                          pmChecklist?.pmChecklistAttachment?.find(
                            (v) => v.pmFormatId === pmF.id
                          )?.attachments
                        }
                      />
                    }
                  />
                );
            }
            return <div key={pmF.id}>{pmF.name}</div>;
          })}
        </div>
      </div>
    </div>
  );
}
