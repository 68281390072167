import { MapPin, Tag } from "lucide-react";
import AppItemInfo from "../AppItemInfo";

interface Asset {
  id: number;
  name: string;
  location?: {
    name?: string | null;
  } | null;
}

interface PmChecklistEquipmentInfoProps {
  isMultipleEquipments: boolean;
  asset?: Asset | null;
  multipleAssets?: Asset[];
}

export default function PmChecklistEquipmentInfo({
  isMultipleEquipments,
  asset,
  multipleAssets,
}: PmChecklistEquipmentInfoProps) {
  if (isMultipleEquipments) {
    return (
      <>
        <AppItemInfo
          label="Equipment List"
          icon={<Tag className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-1">
              {multipleAssets?.map((asset, index) => (
                <p key={asset.id} className="font-sans">
                  {index + 1}. {asset.name}
                </p>
              ))}
            </div>
          }
        />
        <AppItemInfo
          label="Locations"
          icon={<MapPin className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-1">
              {multipleAssets
                ?.map(asset => asset.location?.name)
                .filter((name): name is string => !!name)
                .filter((name, index, self) => self.indexOf(name) === index) // Remove duplicates
                .map((locationName, index) => (
                  <p key={index} className="font-sans">
                    {index + 1}. {locationName}
                  </p>
                ))}
            </div>
          }
        />
      </>
    );
  }

  return (
    <>
      <AppItemInfo
        label="Equipment Name"
        icon={<Tag className="text-primary-900" />}
        content={<p>{asset?.name ?? "-"}</p>}
      />
      <AppItemInfo
        label="Location"
        icon={<MapPin className="text-primary-900" />}
        content={<p>{asset?.location?.name ?? "-"}</p>}
      />
    </>
  );
} 