import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { trpc } from "../../../lib/trpc";
import AppTitle from "../../HomeScreen/components/AppTitle";
import CCUsersOnWorkOrders from "./CommandCentreGraphs/CCUsersOnWorkOrders";
import CCUsersOverview from "./CommandCentreGraphs/CCUsersOverview";

export default function CCStaffAnalytics() {
  const projects = trpc.analytics.getProjectsUnderRCI.useQuery();

  return (
    <>
      <AppTitle title="Users Analytics" />
      <Tabs defaultValue="0">
        <TabsList>
          <TabsTrigger value="0">
            <p>Overview</p>
          </TabsTrigger>
          <TabsTrigger value="1">
            <p>Work Orders</p>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          <CCUsersOverview />
        </TabsContent>
        <TabsContent value="1">
          <div className="grid grid-cols-3 gap-4">
            {projects.data?.map((project) => (
              <CCUsersOnWorkOrders key={project.id} project={project} />
            ))}
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
}
