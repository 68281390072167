import { z } from 'zod';

export const VendorInput = z.object({
  name: z.string().optional(),
  businessAddress: z.string(),
  companyId: z.number(),
  companyWebsite: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  remarks: z.string(),
  workScopeIds: z.array(z.number()),
  attachments: z.array(z.object({
    name: z.string(),
    fileSizeMb: z.string(),
    underProject: z.object({ id: z.number() }),
    underProjectId: z.number(),
    gsPath: z.string(),
    url: z.string(),
  })),
});

export const VendorUpdateInput = VendorInput.extend({
  updatedById: z.string(),
});

export const VendorFilterInput = z.object({
  companyId: z.string(),
  workscopeIds: z.array(z.string()),
  search: z.string().optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
  cursor:  z.number().nullish(),
});

export const WorkScopeFilterInput = z.object({
  companyId: z.string(),
  search: z.string().optional(),
});

export const ProductInput = z.object({
  name: z.string(),
  description: z.string().optional(),
  vendorId: z.number(),
  attachments: z.array(z.object({
    name: z.string(),
    fileSizeMb: z.string(),
    underProject: z.object({ id: z.number() }),
    underProjectId: z.number(),
    gsPath: z.string(),
    url: z.string(),
  })),
});

export const ProductUpdateInput = ProductInput.extend({
  id: z.number(),
});

export const ProductFilterInput = z.object({
  vendorId: z.string(),
  search: z.string().optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
});

export const DeleteProductAttachmentInput = z.object({
  productId: z.string(),
  attachmentId: z.string(),
});

export type VendorInputSchema = z.infer<typeof VendorInput>;
export type VendorUpdateInputSchema = z.infer<typeof VendorUpdateInput>;
export type VendorFilterInputSchema = z.infer<typeof VendorFilterInput>;
export type WorkScopeFilterInputSchema = z.infer<typeof WorkScopeFilterInput>;
export type ProductInputSchema = z.infer<typeof ProductInput>;
export type ProductUpdateInputSchema = z.infer<typeof ProductUpdateInput>;
export type ProductFilterInputSchema = z.infer<typeof ProductFilterInput>;
export type DeleteProductAttachmentInputSchema = z.infer<typeof DeleteProductAttachmentInput>; 