import { useFormContext, useWatch } from "react-hook-form";
import { useMemo } from "react";
import Decimal from "decimal.js";
import { cn } from "../../../lib/utils";

interface FormValues {
  monthlyPaymentValue: string;
  maximumApd: string;
  kpiRows: {
    weightage: string;
    actual: string;
    target: string;
    [key: string]: any;
  }[];
}

interface ApdValueCellProps {
  index: number;
}

export function ApdValueCell({ index }: ApdValueCellProps) {
  const { control } = useFormContext<FormValues>();

  const [monthlyPaymentValue, maximumApd, rowWeightage, actual, target] = useWatch({
    control,
    name: [
      'monthlyPaymentValue',
      'maximumApd',
      `kpiRows.${index}.weightage`,
      `kpiRows.${index}.actual`,
      `kpiRows.${index}.target`
    ],
  });

  const calculatedApdValue = useMemo(() => {
    try {
      const mpv = new Decimal(monthlyPaymentValue || '0');
      const maxApd = new Decimal(maximumApd || '0');
      const weight = new Decimal(rowWeightage || '0');
      
      const value = mpv
        .times(maxApd)
        .times(weight)
        .dividedBy(10000)
        .toNumber();

      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    } catch (error) {
      return '0.00';
    }
  }, [monthlyPaymentValue, maximumApd, rowWeightage]);

  const showRed = actual && target && parseFloat(actual) < parseFloat(target);

  return (
    <td className={cn(
      "border p-2 text-sm text-right",
      showRed && "text-red-600 font-bold"
    )}>
      {calculatedApdValue}
    </td>
  );
} 