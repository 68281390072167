import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { Files, Tag } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import AppItemInfo from "../../../AppItemInfo";
import AppDocument from "../../../AppDocument";
import AppButton from "../../../AppButton";
import { DRAWER_UPDATE_STATE, PRODUCT_FORM_DRAWER } from "../../AppDrawer";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import ConfirmDeleteBtnWithDialog from "../../../dialogs/ConfirmDeleteWithTextDialog";

interface ProductDetailDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  productId: string;
}

export function ProductDetailDialog({
  open,
  onOpenChange,
  productId,
}: ProductDetailDialogProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const utils = trpc.useUtils();
  const nav = useNavigate();

  const { data: product, isLoading } = trpc.vendor.getOneProduct.useQuery(
    productId,
    {
      enabled: !!productId,
    }
  );

  const { mutateAsync: deleteProduct } = trpc.vendor.deleteProduct.useMutation({
    onSuccess: () => {
      utils.vendor.getProducts.invalidate();
      onOpenChange(false);
    },
  });

  const handleEdit = () => {
    setSearchParams((params) => {
      params.set("drawerType", PRODUCT_FORM_DRAWER);
      params.set("drawerState", DRAWER_UPDATE_STATE);
      params.set("productId", productId);
      return params;
    });
    onOpenChange(false);
  };

  if (isLoading) {
    return <DrawerFormSkeleton />;
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Product Details</DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <AppItemInfo
            icon={<Tag className="text-primary-900" />}
            label="Name"
            content={<p className="font-sans">{product?.name ?? "-"}</p>}
          />

          <AppItemInfo
            icon={<Tag className="text-primary-900" />}
            label="Description"
            content={<p className="font-sans">{product?.description ?? "-"}</p>}
          />

          <AppItemInfo
            icon={<Files className="text-primary-900" />}
            label="Attachments"
            content={
              <div className="flex flex-col gap-4">
                {product?.attachments?.length ? (
                  product?.attachments.map((att) => (
                    <AppDocument
                      key={att.id}
                      att={{
                        ...att,
                        createdById: att.createdById ?? undefined,
                        updatedById: att.updatedById ?? undefined,
                        deletedById: att.deletedById ?? undefined,
                        createdOn: att.createdOn.toISOString(),
                        deletedOn: att.deletedOn?.toISOString() ?? undefined,
                        status: att.status ?? undefined,
                        fileSizeMb: att.fileSizeMb?.toString() ?? undefined,
                      }}
                    />
                  ))
                ) : (
                  <p className="font-sans text-neutral-900">-</p>
                )}
              </div>
            }
          />

          <div className="flex justify-end gap-4">
            <AppButton label="Edit" variant="outline" onClick={handleEdit} />
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={product?.name ?? ""}
              onDeleteConfirm={async () => {
                if (productId) {
                  await deleteProduct(productId);
                }
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
