import { PDFViewer } from "@react-pdf/renderer";
import { JkrApdSummaryPdf } from "@cerev-cmms/pdf";
import { useParams, useSearchParams } from "react-router-dom";
import { trpc } from "../../lib/trpc";
import { useMemo, useEffect, useRef } from "react";
import { useAppSelector } from "../../redux/store";
import { useForm, useFieldArray } from "react-hook-form";
import { EditableCell } from "./components/EditableCell";
import { format, addMonths, subMonths, addDays, parseISO } from "date-fns";
import AppButton from "../../components/AppButton";
import { ChevronLeft, ChevronRight, Save, Printer } from "lucide-react";
import { kpiDataDefault } from "./components/kpiDataDefault";
import { METHODS } from "http";
import { AppDatePicker } from "../../components/AppDatePicker";
import { FormProvider } from "react-hook-form";
import { cn } from "../../lib/utils";
import { WeightageTotal } from "./components/WeightageTotal";
import { ApdValueCell } from "./components/ApdValueCell";
import { DemeritPointsImposedCell } from "./components/DemeritPointsImposedCell";
import { ApdValueDeductedCell } from "./components/ApdValueDeductedCell";
import { ApdValueDeductedTotal } from "./components/ApdValueDeductedTotal";
import { TotalApdValue } from "./components/TotalApdValue";
import { TotalDemeritPoint } from "./components/TotalDemeritPoint";
import { TotalDemeritPointsImposed } from "./components/TotalDemeritPointsImposed";
import { useReactToPrint } from "react-to-print";

interface KPIRow {
  id?: string;
  no: string;
  kpi: string;
  piNo: string;
  performanceIndicator: string;
  target: string;
  actual: string;
  demeritPoint: string;
  demeritPointsImposed: string;
  weightage: string;
  apdValueDeducted: string;
}

interface FormValues {
  kpiRows: KPIRow[];
  verifiedByJkr: Date | null;
  monthlyPaymentValue: string;
  maximumApd: string;
}

interface AnalyticsValue {
  kpiRows: KPIRow[];
  verifiedByJkr: string;
  monthlyPaymentValue: string;
  maximumApd: string;
}

export function JkrApdSummaryPdfReview() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParams, setSearchParams] = useSearchParams();
  const utils = trpc.useUtils();

  const dateParam = searchParams.get("date");
  const currentDate = dateParam ? new Date(dateParam) : new Date();

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const { data: analyticsData } =
    trpc.pdfReports.getOneCustomProjectAnalytic.useQuery(
      {
        projectId: activeProj?.id?.toString() ?? "",
        name: "JKR_APD",
        dateStart: firstDayOfMonth.toISOString(),
        dateEnd: lastDayOfMonth.toISOString(),
      },
      {
        enabled: !!activeProj?.id,
      }
    );

  const methods = useForm<FormValues>({
    defaultValues: {
      kpiRows: [],
      verifiedByJkr: null,
      monthlyPaymentValue: "",
      maximumApd: "",
    },
  });

  const { fields, replace } = useFieldArray<FormValues>({
    control: methods.control,
    name: "kpiRows",
    keyName: "id",
  });

  // Initial KPI data
  const kpiData: KPIRow[] = useMemo(() => kpiDataDefault, []);

  // Add computed value for apdMax with formatting
  const computedApdMax = useMemo(() => {
    const mpv = parseFloat(methods.watch("monthlyPaymentValue") || "0");
    const maxApd = parseFloat(methods.watch("maximumApd") || "0");
    if (isNaN(mpv) || isNaN(maxApd)) return "0.00";
    const value = (mpv * maxApd) / 100;
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }, [methods.watch("monthlyPaymentValue"), methods.watch("maximumApd")]);

  // Add function to handle monthly payment value formatting
  const handleMonthlyPaymentChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, "");
    methods.setValue("monthlyPaymentValue", rawValue);
  };

  useEffect(() => {
    if (analyticsData?.data?.value) {
      const savedData = analyticsData.data.value as unknown as AnalyticsValue;
      const dataWithIds = savedData.kpiRows.map((row) => ({
        ...row,
        id: row.id || crypto.randomUUID(),
      }));
      replace(dataWithIds);
      methods.setValue(
        "verifiedByJkr",
        savedData.verifiedByJkr ? parseISO(savedData.verifiedByJkr) : null
      );
      methods.setValue("monthlyPaymentValue", savedData.monthlyPaymentValue);
      methods.setValue("maximumApd", savedData.maximumApd);
    } else {
      const dataWithIds = kpiData.map((row) => ({
        ...row,
        id: crypto.randomUUID(),
      }));
      replace(dataWithIds);

      // Set default values for the new fields
      methods.setValue("verifiedByJkr", addDays(firstDayOfMonth, 5));
      methods.setValue("monthlyPaymentValue", "0");
      methods.setValue("maximumApd", "0");
    }
  }, [analyticsData?.data?.value, dateParam]);

  const upsertMutation =
    trpc.pdfReports.upsertCustomProjectAnalytic.useMutation({
      onSuccess: () => {
        utils.pdfReports.getOneCustomProjectAnalytic.invalidate();
      },
    });

  const handleMonthChange = (direction: "prev" | "next") => {
    const newDate =
      direction === "prev"
        ? subMonths(currentDate, 1)
        : addMonths(currentDate, 1);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("date", newDate.toISOString());
    setSearchParams(newSearchParams);
  };

  const handleSave = () => {
    const currentValues = methods.getValues();
    if (activeProj?.id) {
      upsertMutation.mutate({
        projectId: activeProj.id.toString(),
        name: "JKR_APD",
        description: "JKR APD Report Values",
        value: {
          kpiRows: currentValues.kpiRows,
          verifiedByJkr: currentValues.verifiedByJkr?.toISOString() ?? "",
          monthlyPaymentValue: currentValues.monthlyPaymentValue,
          maximumApd: currentValues.maximumApd,
        },
        date: currentDate.toISOString(),
      });
    }
  };

  // Add the KPI report query
  const { mutate: getKpiReport, isPending: isKpiReportLoading } =
    trpc.analytics.getJkrKpiReport.useMutation({
      onSuccess: (data) => {
        // Update the form with the calculated values
        const updatedKpiRows = fields.map((row) => {
          if (row.piNo === "1B") {
            return { ...row, actual: data["KPI 1B"].toString() };
          } else if (row.piNo === "1C") {
            return { ...row, actual: data["KPI 1C"].toString() };
          } else if (row.piNo === "1D") {
            return { ...row, actual: data["KPI 1D"].toString() };
          } else if (row.piNo === "1E") {
            return { ...row, actual: data["KPI 1E"].toString() };
          } else if (row.piNo === "1F") {
            return { ...row, actual: data["KPI 1F"].toString() };
          } else if (row.piNo === "2A") {
            return { ...row, actual: data["KPI 2A"].toString() };
          } else if (row.piNo === "2B") {
            return { ...row, actual: data["KPI 2B"].toString() };
          } else if (row.piNo === "2C") {
            return { ...row, actual: data["KPI 2C"].toString() };
          }
          return row;
        });

        replace(updatedKpiRows);
      },
    });

  const handleCalculateKpis = () => {
    if (!activeProj?.id) return;

    getKpiReport({
      projectId: activeProj.id,
      month: currentDate.getMonth() + 1, // date-fns months are 0-based
      year: currentDate.getFullYear(),
    });
  };

  // Add ref for printing
  const contentRef = useRef<HTMLDivElement>(null);

  // Add print function with title and scaling
  const handlePrint = useReactToPrint({
    contentRef,
    pageStyle: `
      @page { 
        size: A4 potrait;
        margin: 10mm 5mm; /* Reduced margins */
      }
      @media print {
        html, body {
          height: 100%;
          margin: 0 !important;
          padding: 0 !important;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
          font-size: 9pt !important;
        }
        table {
          font-size: 7pt !important;
          width: 100% !important;
          page-break-inside: avoid;
        }
        td, th {
          padding: 2px 4px !important;
        }
        .print-scaling {
          width: 100% !important;
          transform: scale(0.98);
          transform-origin: center;
        }
        /* Ensure header info is compact */
        .header-info {
          font-size: 8pt !important;
          margin-bottom: 8px !important;
        }
        /* Adjust input sizes */
        input {
          padding: 1px 2px !important;
        }
        /* Ensure title is properly sized */
        .print-title {
          font-size: 12pt !important;
          margin-bottom: 8px !important;
        }
      }
    `,
    copyShadowRoots: true,
    documentTitle: `APD Summary - ${format(currentDate, "MMMM yyyy")}`,
  });

  return (
    <FormProvider {...methods}>
      <div className="p-6">
        <div className="mb-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <AppButton
                variant="ghost"
                className="p-2"
                onClick={() => handleMonthChange("prev")}
                icon={<ChevronLeft className="h-4 w-4" />}
              />
              <h1 className="text-2xl font-bold">
                {format(currentDate, "MMMM yyyy")}
              </h1>
              <AppButton
                variant="ghost"
                className="p-2"
                onClick={() => handleMonthChange("next")}
                icon={<ChevronRight className="h-4 w-4" />}
              />
            </div>
            <div className="flex items-center gap-2">
              <AppButton
                variant="outline"
                onClick={handleCalculateKpis}
                isLoading={isKpiReportLoading}
                label="Calculate KPIs"
              />
              <AppButton
                variant="outline"
                icon={<Save className="h-4 w-4" />}
                onClick={handleSave}
                isLoading={upsertMutation.isPending}
              >
                Save Changes
              </AppButton>
              <AppButton
                className="print:hidden"
                variant="outline"
                icon={<Printer className="w-4 h-4" />}
                onClick={() => handlePrint()}
              >
                Print
              </AppButton>
            </div>
          </div>

          <div ref={contentRef}>
            <div className="print-scaling">
              <h1 className="hidden print:block print:text-center print-title">
                APD Summary - {format(currentDate, "MMMM yyyy")}
              </h1>

              <div className="grid grid-cols-2 gap-4 text-sm header-info">
                <div>
                  <p className="mb-2">
                    <span className="font-semibold">Period Date/Time:</span>{" "}
                    {format(firstDayOfMonth, "MMM yyyy")}
                  </p>
                  <div className="flex items-center gap-2 mb-2">
                    <span className="font-semibold">Verified By JKR:</span>
                    <AppDatePicker
                      name="verifiedByJkr"
                      label=""
                      className="print:border-none print:shadow-none print:p-0 print:bg-transparent"
                    />
                  </div>
                  <p className="flex items-center">
                    <span className="font-semibold whitespace-nowrap">
                      Monthly Payment Value (MPV):
                    </span>
                    <input
                      type="text"
                      {...methods.register("monthlyPaymentValue")}
                      className="ml-2 px-2 py-1 focus:outline-none print:border-none print:p-0 w-32 text-right border"
                      placeholder="Enter MPV"
                    />
                  </p>
                </div>
                <div>
                  <p className="flex items-center mb-2">
                    <span className="font-semibold whitespace-nowrap">
                      Maximum APD (%):
                    </span>
                    <input
                      type="text"
                      {...methods.register("maximumApd")}
                      className="ml-2 px-2 py-1 focus:outline-none print:border-none print:p-0 w-32 text-right border"
                      placeholder="Enter Maximum APD"
                    />
                  </p>
                  <p className="flex items-center">
                    <span className="font-semibold whitespace-nowrap">
                      APD Max:
                    </span>
                    <span className="ml-2 px-2 py-1 w-32 text-right border print:border-none">
                      RM {computedApdMax}
                    </span>
                  </p>
                </div>
              </div>

              <table className="w-full border-collapse border border-gray-300 mt-2">
                <thead>
                  <tr className="bg-gray-100 print:bg-gray-100">
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      No.
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      Key Performance Indicators (KPI)
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      PI No.
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      Performance Indicators (PI)
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      Target (%)
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      Actual (%)
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      Demerit Point
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      Demerit Points Imposed
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      Weightage (%)
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      APD Value (RM)
                    </th>
                    <th className="border p-2 text-sm print:text-[7pt] print:p-1 print:font-normal">
                      APD Value Deducted (RM)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.reduce((acc: JSX.Element[], row, index, array) => {
                    const prevRow = array[index - 1];
                    const isNewSection = !prevRow || prevRow.no !== row.no;

                    if (isNewSection) {
                      const rowSpan = array.filter(
                        (r, i) => i >= index && r.no === row.no
                      ).length;

                      acc.push(
                        <tr key={row.id} className="hover:bg-gray-50">
                          <td
                            rowSpan={rowSpan}
                            className="border p-2 text-sm align-top"
                          >
                            {row.no}
                          </td>
                          <td
                            rowSpan={rowSpan}
                            className="border p-2 text-sm align-top"
                          >
                            {row.kpi}
                          </td>
                          <td className="border p-2 text-sm">{row.piNo}</td>
                          <td className="border p-2 text-sm">
                            {row.performanceIndicator}
                          </td>
                          <EditableCell
                            index={index}
                            fieldName="target"
                            control={methods.control}
                          />
                          <EditableCell
                            index={index}
                            fieldName="actual"
                            control={methods.control}
                          />
                          <EditableCell
                            index={index}
                            fieldName="demeritPoint"
                            control={methods.control}
                          />
                          <DemeritPointsImposedCell index={index} />
                          <EditableCell
                            index={index}
                            fieldName="weightage"
                            control={methods.control}
                          />
                          <ApdValueCell index={index} />
                          <ApdValueDeductedCell index={index} />
                        </tr>
                      );
                    } else {
                      acc.push(
                        <tr key={row.id} className="hover:bg-gray-50">
                          <td className="border p-2 text-sm">{row.piNo}</td>
                          <td className="border p-2 text-sm">
                            {row.performanceIndicator}
                          </td>
                          <EditableCell
                            index={index}
                            fieldName="target"
                            control={methods.control}
                          />
                          <EditableCell
                            index={index}
                            fieldName="actual"
                            control={methods.control}
                          />
                          <EditableCell
                            index={index}
                            fieldName="demeritPoint"
                            control={methods.control}
                          />
                          <DemeritPointsImposedCell index={index} />
                          <EditableCell
                            index={index}
                            fieldName="weightage"
                            control={methods.control}
                          />
                          <ApdValueCell index={index} />
                          <ApdValueDeductedCell index={index} />
                        </tr>
                      );
                    }
                    return acc;
                  }, [])}
                  <tr className="font-bold bg-gray-100">
                    <td
                      colSpan={6}
                      className="border p-2 text-sm print:text-[7pt] print:p-1"
                    >
                      GRAND TOTAL
                    </td>
                    <TotalDemeritPoint />
                    <TotalDemeritPointsImposed />
                    <WeightageTotal />
                    <TotalApdValue />
                    <ApdValueDeductedTotal />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
